import React, { memo } from 'react'
import "../../Assets/Css/SEOTemplate/NewFaq.css"
import parser from "html-react-parser";
import { Helmet } from 'react-helmet';

function NewFaq({ Item,id }) {
  console.log("faqData",Item);
  const faqIndex = Item?.faq?.findIndex((item:any) => item?.type === 'FAQs');
  return (  
    <div className=" ">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: Item?.faq[faqIndex]?.faqData?.map((item:any) => {
              return {
                '@type': 'Question',
                name: item.question,
                acceptedAnswer: {
                  '@type': 'Answer',
                  text: item.answer
                }
              };
            })
          })}
        </script>
      </Helmet>
      <div className="accordion accordion-flush w-100" id={id}>
          {Item?.faq?.map((faqdata: any, faqindex: number) => {
              if (faqindex <= 9) {
                return (
                  <div className="accordion-item shadow-sm mb-2" key={faqindex}>
                    <h2 className="accordion-header " id={"heading" + faqindex}>
                      <button
                        className={`accordion-button boldfont faq-btns fw-bold ${
                          faqindex === 0 ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapse" + faqindex}
                        aria-expanded={faqindex === 0 ? "true" : "false"}
                        aria-controls={"collapse" + faqindex}
                      >
                        {faqdata?.question}
                      </button>
                    </h2>
                    <div
                      id={"collapse" + faqindex}
                      className={`accordion-collapse collapse ${
                        faqindex === 0 ? "show" : ""
                      }`}
                      aria-labelledby={"heading" + faqindex}
                      data-bs-parent={"#" + id}
                    >
                      <div className="accordion-body">
                        {parser(faqdata?.answer)}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
  </div>
  )
}
export default memo(NewFaq);
