import React from 'react'
import { Rating } from '@mui/material';
import "../../Assets/Css/SEOTemplate/ratingPanel.css"

function RatingPanel({Item}) {
    const RatingPanleDatas = JSON.parse(Item.content);
  return (
    <div>
      <div className="ratingPanel-ContentBox p-3 my-3 row d-flex justify-content-center">
        {RatingPanleDatas.map((item:any, index:number) => (
          <>
            <div className="Container-img mx-auto col-sm-6 col-md-3 d-flex justify-content-center align-items-center" key={index}>
              <img
                className="img-content"
                src={item.RatingImg}
                alt={item.RatingAltText}
                height={50}
                width={50}
              />
              <div className="img-name row ms-3">
                {item.ratingName} 
                <div className="name-ratings p-0 d-flex align-items-center">
                  {item.Rating}/5
                  <div className="ratings-Stars mt-1 ms-2">
                    <Rating
                      className="simple-controlled size-small"
                      style={{ opacity: "1" }}
                      value={item?.Rating}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  )
}

export default RatingPanel
