import React, { useState } from "react";
import localImages from "../../Constant/localImagesNew";
import parser from "html-react-parser";
import "../../Assets/Css/SEOTemplate/gradeCard.css"
import EastIcon from '@mui/icons-material/East';
import gradeTutoringDetails from "../../Constant/dataConstant" 
function GradeCard({Item}) { 
  // const CourseTypeDatas = JSON.parse(Item.content);
  const [tabValue, setTabValue] = useState<number>(0);
  const subjectFeature=JSON.parse(Item?.content)
  console.log("sunect",subjectFeature)
  return (
    <div className="commonPad">
       <h1 className="headingFont text-center">
      {subjectFeature?.Heading}
    </h1>
      <div className="text-center mx-auto mb-3 font16 subscriptionSubHeading mt-3">
         
         {subjectFeature?.SubHeading}
        </div>
     <div className="d-flex row justify-content-bewteen">
 {  subjectFeature?.SubjectFeatureArr?.map((itm:any , index:any)=> <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 gradeDiv"  >
           
   <div className={`gradeCard p-3 h-100 shadow border d-flex flex-column justify-content-between`}>
    <div>
        <div className='cardheading fw-bold mt-3 mb-2 lh-sm'>{itm?.HeadingFeature}</div>
        <div className='font16 text-Justify'> {parser(itm?.DescriptionFeature)}</div>
        </div>
        <div className="row d-flex justify-content-end  ">
            <div className="col-md-2 text-center shadow bg-light p-2">
                <EastIcon/>
            </div>

        </div>
      </div>
   </div> )} 
    </div></div>
     
  );
}

export default GradeCard;
