// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Images/NewComponentAllImages/Rectanglebg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradebg {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    padding: 3% 7% 3% 7%;
}

.gradetext {
    text-align: justify;
}
.gradediscription{
    font-size: 18px;
    letter-spacing: 0.77px;
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/SEOTemplate/gradeBanner.css"],"names":[],"mappings":"AAAA;IACI,yDAA2E;IAC3E,sBAAsB;IACtB,kBAAkB;IAClB,4BAA4B;IAC5B,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,sBAAsB;AAC1B","sourcesContent":[".gradebg {\n    background-image: url(\"../../Images/NewComponentAllImages/Rectanglebg.jpg\");\n    background-size: cover;\n    position: relative;\n    background-repeat: no-repeat;\n    padding: 3% 7% 3% 7%;\n}\n\n.gradetext {\n    text-align: justify;\n}\n.gradediscription{\n    font-size: 18px;\n    letter-spacing: 0.77px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
