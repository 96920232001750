import React from "react";
import localImages from "../../Constant/localImagesNew";

const SubjectDetails = [
    {
        imgPath: localImages?.science,
        title: "Science",
        desc: "Dive into the fascinating world of Science with Lurnigo. Our comprehensive approach blends engaging content, hands-on exploration, and personalized guidance to cultivate a deep understanding of scientific principles. From biology to physics, embark on a journey of discovery and mastery.",
    },
    {
        imgPath: localImages?.maths,
        title: "Maths",
        desc: "Unlock the power of numbers with Lurnigo. Tailored for success, our academics in Math combines conceptual clarity, real-world application, and individualized support to elevate mathematical proficiency. Whether tackling algebra, geometry, or calculus, build a solid foundation for mathematical excellence.",
    },
    {
        imgPath: localImages?.english,
        title: "English",
        desc: "Master the art of language and literature with Lurnigo. Our academics in English focuses on honing reading, writing, and communication skills. Through literary exploration, grammar refinement, and personalized guidance, embark on a transformative journey towards linguistic proficiency and academic success.",
    },
    {
        imgPath: localImages?.coding,
        title: "Coding",
        desc: "Elevate your tech skills with Lurnigo's coding program. Enhance problem-solving, programming, and analytical abilities through practical exercises and personalized mentorship. Start your journey to coding mastery and professional growth in the world of software development and computer science.",
    },
];

export default function SubjectCard({Item}) {

    const subject=JSON.parse(Item?.content)
    console.log("sunect",subject)
    return (
        <div className="commonPad">
        <div className="row">
            {subject?.map((item:any, index:any) => (
                <div className="d-flex col-xl-3 col-md-6 col-12 mt-xl-0 mt-3" key={index}>
                    <div className="card p-4">
                        <div>
                            <img src={item?.SubjectImg} alt={item?.SubjectAltText} className="card-img-top w-25" />
                        </div>
                        <div className="card-body p-0 pt-3">
                            <div className="card-title fs-3 fw-bold">
                                {item?.Heading}
                            </div>
                            <div className="card-text" style={{"textAlign":"justify"}}>
                                {item?.Description}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        </div>
    );
}