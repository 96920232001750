const localImages = {
  logo: require("../Assets/Images/Home/logo.png"),
  learnSolleft: require("../Assets/Images/NewHome/learnSolleft.png"),
  learnSolright: require("../Assets/Images/NewHome/learnSolright.png"),
  collegeConsulting: require("../Assets/Images/NewHome/collegeConsulting.png"),
  testPrep: require("../Assets/Images/NewHome/testPrep.png"),
  academic: require("../Assets/Images/NewHome/academic.png"),
  logoIcon: require("../Assets/Images/NewHome/logoIcon.png"),
  homeBanner: require("../Assets/Images/NewHome/homeBanner.jpg"),
  homeBannerMob: require("../Assets/Images/NewHome/homeBannerMob.jpg"),
  
  /*****************************Video*************************** */
  roadmapvideo: require("../Assets/Video/RoadmapAnimation.mp4"),
  webanim: require("../Assets/Video/WebsiteAnimation.mp4"),
  TutorsCollaborative: require("../Assets/Video/TutorsCollaborative.mp4"),
  homeBannerVideo: require("../Assets/Video/homeBannerVideo.mp4"),
  CollegeConsulting: require("../Assets/Video/CollegeConsulting.webm"),
  ParentCollabTestemonial: require("../Assets/Video/ParentCollabTestemonial.mp4"),

  oneOnOneLive: require("../Assets/Images/NewHome/one-on-one-Live.png"),
  regularAssessment: require("../Assets/Images/NewHome/regularAssessment.png"),
  dedicatedDoubt: require("../Assets/Images/NewHome/dedicatedDoubt.png"),
  grandTest: require("../Assets/Images/NewHome/grandTest.png"),
  weeklyGroup: require("../Assets/Images/NewHome/weeklyGroup.png"),
  roadmapStrategy: require("../Assets/Images/NewHome/roadmapStrategy.png"),
  counsellorStrategy: require("../Assets/Images/NewHome/counsellorStrategy.png"),
  ongoingAlumni: require("../Assets/Images/NewHome/ongoingAlumni.png"),

  collegePredictor: require("../Assets/Images/NewHome/collegePredictor.png"),
  collegePredright: require("../Assets/Images/NewHome/collegePredright.png"),
  collegePredleft: require("../Assets/Images/NewHome/collegePredleft.png"),
  blog1: require("../Assets/Images/NewHome/blog1.png"),
  blog2: require("../Assets/Images/NewHome/blog2.png"),
  blog3: require("../Assets/Images/NewHome/blog3.png"),
  bookSession: require("../Assets/Images/NewHome/bookSession.png"),
  University1: require("../Assets/Images/CollegeLogo/logo1.png"),
  University2: require("../Assets/Images/CollegeLogo/logo2.png"),
  University3: require("../Assets/Images/CollegeLogo/logo3.png"),
  University4: require("../Assets/Images/CollegeLogo/logo4.png"),
  University5: require("../Assets/Images/CollegeLogo/logo5.png"),
  University6: require("../Assets/Images/CollegeLogo/logo6.png"),
  University7: require("../Assets/Images/CollegeLogo/logo7.png"),
  University8: require("../Assets/Images/CollegeLogo/logo8.png"),
  University9: require("../Assets/Images/CollegeLogo/logo9.png"),
  footerMapImg: require("../Assets/Images/NewHome/footerMapImg.png"),
  /********************Academic********************** */
  maths: require("../Assets/Images/Academics/maths.png"),
  science: require("../Assets/Images/Academics/science.png"),
  english: require("../Assets/Images/Academics/english.png"),
  coding: require("../Assets/Images/Academics/coding.png"),
  classwork: require("../Assets/Images/Academics/classwork.png"),
  advLearn: require("../Assets/Images/Academics/advLearn.png"),
  awardWinning: require("../Assets/Images/Academics/awardWinning.png"),
  HandpickedTeachers: require("../Assets/Images/Academics/HandpickedTeachers.png"),
  PersonalisedLearning: require("../Assets/Images/Academics/PersonalisedLearning.png"),
  oneto1LIVETutoring: require("../Assets/Images/Academics/1to1LIVETutoring.png"),
  academicBanner: require("../Assets/Images/Academics/academicBanner.png"),

  /********************Testprep********************** */
  TestPrepBanner: require("../Assets/Images/Testprep/testPrepBanner.png"),
  knowledge: require("../Assets/Images/Testprep/knowledge.png"),
  studentEnroll: require("../Assets/Images/Testprep/studentEnroll.png"),

  /********************College Consulting********************** */
  ccLeftArrow: require("../Assets/Images/CollegeConsulting/ccLeftArrow.png"),
  ccRightArrow: require("../Assets/Images/CollegeConsulting/ccRightArrow.png"),
  dummyExpert: require("../Assets/Images/CollegeConsulting/dummyExpert.png"),
  thankYou: require("../Assets/Images/CollegeConsulting/thankYou.png"),

  /****************************collegePredictorBanner********************************** */
  collegePredictorBanner: require("../Assets/Images/CollegePredictor/collegePredictorBanner.png"),
  linkedin: require("../Assets/Images/AlumniNetwork/linkedin.png"),
  twitter: require("../Assets/Images/AlumniNetwork/twitter.png"),

  /****************************TutorNetwork********************************** */
  tutorBanner: require("../Assets/Images/TutorNetwork/tutorBanner.png"),
  jobOpening: require("../Assets/Images/TutorNetwork/jobOpening.png"),
  TefenciaBarretto: require("../Assets/Images/TutorNetwork/TefenciaBarretto.jpg"),
  AnikethSam: require("../Assets/Images/TutorNetwork/AnikethSam.jpg"),
  KANHAIYATHAKUR: require("../Assets/Images/TutorNetwork/KANHAIYATHAKUR.jpg"),
  MasumAhmed: require("../Assets/Images/TutorNetwork/MasumAhmed.jpg"),
  NoorHassanKhan: require("../Assets/Images/TutorNetwork/NoorHassanKhan.jpg"),
  PARKHIRAJVANSHI: require("../Assets/Images/TutorNetwork/PARKHIRAJVANSHI.jpg"),
  PrathibaRajalakshmi: require("../Assets/Images/TutorNetwork/PrathibaRajalakshmi.jpg"),
  ROJIBALA: require("../Assets/Images/TutorNetwork/ROJIBALA.jpg"),
  SARITACHOPRA: require("../Assets/Images/TutorNetwork/SARITACHOPRA.jpg"),

  /****************************Login*************************** */
  loginleftarrow: require("../Assets/Images/SignIn/loginleftarrow.png"),
  loginrightarrow: require("../Assets/Images/SignIn/loginrightarrow.png"),

  /****************************Book Session Page****************************** */
  step1Img: require("../Assets/Images/bookSession/step1Img.png"),
  step2Img: require("../Assets/Images/bookSession/step2Img.png"),
  step3Img: require("../Assets/Images/bookSession/step3Img.png"),
  step4Img: require("../Assets/Images/bookSession/step4Img.png"),
  step5Img: require("../Assets/Images/bookSession/step5Img.png"),
  step6Img: require("../Assets/Images/bookSession/step6Img.png"),
  feedbackPopup: require("../Assets/Images/bookSession/feedbackPopup.png"),

  /****************************Demo Dashboard****************************** */
  demoImg: require("../Assets/Images/bookSession/demoImg.png"),
  teacherImg: require("../Assets/Images/bookSession/teacherImg.jpg"),
  firelogo: require("../Assets/Images/bookSession/firelogo.png"),
  teacherAssign: require("../Assets/Images/bookSession/teacherAssign.png"),
  trialComplete: require("../Assets/Images/bookSession/trialComplete.png"),
  trialConfirm: require("../Assets/Images/bookSession/trialConfirm.png"),
  trialSession: require("../Assets/Images/bookSession/trialSession.png"),
  counsellingComplete: require("../Assets/Images/bookSession/counsellingComplete.png"),

  homeWork: require("../Assets/Images/bookSession/homeWork.webp"),
  Lecture: require("../Assets/Images/bookSession/Lecture.webp"),
  moduletest: require("../Assets/Images/bookSession/moduletest.webp"),

  /*************************COURSE DETAILS*********************** */
  offerimg: require("../Assets/Images/courses/offer.png"),
  upcominIcon: require("../Assets/Images/NewHome/upcominIcon.png"),

  /*********************My Cart*********************** */
  cart_empty: require("../Assets/Images/json/cart_empty.json"),
  /************************Footer********************************/
  whitelogo: require("../Assets/Images/footer/LurniGo Logo Final-1-03.png"),
  footerimage: require("../Assets/Images/footer/footerimg 2.png"),
  award: require("../Assets/Images/Home/award.png"),
  award2: require("../Assets/Images/Home/award2.png"),
  naac: require("../Assets/Images/Home/naac.png"),
  stem: require("../Assets/Images/Home/stem.png"),
  carouselimg: require("../Assets/Images/Home/Frame 9114.png"),
  dppimg: require("../Assets/Images/Home/dppNew.png"),

  /********************************DSAT******************************/
  dsatBanner: require('../Assets/Images/dsat/dsatBanner.png'),
  Customization: require('../Assets/Images/dsat/Customization.png'),
  demandresourceBanner: require('../Assets/Images/dsat/demandresourceBanner.jpg'),
  demandresourceIcon: require('../Assets/Images/dsat/demandresourceIcon.webp'),
  liveClassBanner: require('../Assets/Images/dsat/liveClassBanner.jpg'),
  liveClassIcon: require('../Assets/Images/dsat/liveClassIcon.webp'),
  practiceTestBanner: require('../Assets/Images/dsat/practiceTestBanner.jpg'),
  practiceTestIcon: require('../Assets/Images/dsat/practiceTestIcon.png'),
  detailsAnalysisBanner: require('../Assets/Images/dsat/detailsAnalysisBanner.jpg'),
  detailsAnalysisIcon: require('../Assets/Images/dsat/detailsAnalysisIcon.webp'),
  oneonOneLessonsBanner: require('../Assets/Images/dsat/oneonOneLessonsBanner.jpg'),
  oneonOneLessonsIcon: require('../Assets/Images/dsat/oneonOneLessonsIcon.webp'),
  dsathintImg: require('../Assets/Images/dsat/dsathintImg.png'),

  averageScore: require("../Assets/Images/dsat/averageScore.svg"),
  studentEnrollSVG: require("../Assets/Images/dsat/studentEnrollSVG.svg"),
  averageScoreBoost: require("../Assets/Images/dsat/averageScoreBoost.svg"),

  dummy1: require("../Assets/Images/UserLecture/soc2.png"),
  dummy2: require("../Assets/Images/UserLecture/storyChangeBanner.png"),
  geo: require("../Assets/Images/UserLecture/geo.png"),

  /************************Lecture User New Dashboard***************** */
  DayType1: require("../Assets/Images/UserLecture/DayType1.webp"),
  DayType2: require("../Assets/Images/UserLecture/DayType2.webp"),
  TotalQuestion: require("../Assets/Images/UserLecture/TotalQuestion.webp"),
  PracticeQuestion: require("../Assets/Images/UserLecture/PracticeQuestion.webp"),
  Homework: require("../Assets/Images/UserLecture/Homework.webp"),
  correctIcon: require("../Assets/Images/UserLecture/Correct.webp"),
  Wrong: require("../Assets/Images/UserLecture/Wrong.webp"),
  percentage: require("../Assets/Images/UserLecture/percentage.webp"),
  recordedLecture: require("../Assets/Images/UserLecture/recordedLecture.webp"),
  feedback: require("../Assets/Images/UserLecture/feedback.webp"),
  calculatorIcon: require("../Assets/Images/UserLecture/calculatorIcon.webp"),
  /*********************************** Detail Analysis*************************************/
  rightTick: require('../Assets/Images/icons/right-tick.png'),
  star: require('../Assets/Images/icons/star.png'),
  clock: require('../Assets/Images/icons/clock.png'),


  /***********************Learn Marketing New*************************** */
  learnBanner: require('../Assets/Images/LearnLanding/learnBanner.png'),
  learnAcademicTutoring: require('../Assets/Images/LearnLanding/learnAcademicTutoring.png'),
  learnCollegeCounseling: require('../Assets/Images/LearnLanding/learnCollegeCounseling.png'),
  learnTestPrep: require('../Assets/Images/LearnLanding/learnTestPrep.png'),

  learnInteractiveoneononeLessons: require('../Assets/Images/LearnLanding/learnInteractiveoneononeLessons.png'),
  learnLiveOnlineClasses: require('../Assets/Images/LearnLanding/learnLiveOnlineClasses.png'),
  learnDetailedPerformanceAnalysis: require('../Assets/Images/LearnLanding/learnDetailedPerformanceAnalysis.png'),
  learnOnDemandResources: require('../Assets/Images/LearnLanding/learnOnDemandResources.png'),
  learnFullLengthPracticeTests: require('../Assets/Images/LearnLanding/learnFullLengthPracticeTests.png'),
  learnPopupBg: require('../Assets/Images/LearnLanding/learnPopupBg.png'),

  HappyLearners: require("../Assets/Images/json/HappyLearners.json"),
  ExpertTutors: require("../Assets/Images/json/ExpertTutors.json"),
  HoursLiveTutoring: require("../Assets/Images/json/HoursLiveTutoring.json"),
  studentadmit: require("../Assets/Images/json/studentadmit.json"),
  bookSessionLine: require("../Assets/Images/LearnLanding/bookSessionLine.png"),

  booksessionimg: require("../Assets/Images/LandingPage/bookSessionImage.png"),
  landingPageBanner: require("../Assets/Images/LandingPage/landingpagebannerImg.png"),
  landingPagesection: require("../Assets/Images/LandingPage/chat.png"),

  timerIcon: require("../Assets/Images/Academics/stop-watch.png"),
  timerIconOrange: require("../Assets/Images/Academics/timerIconOrange.png"),
  MITlogo: require("../Assets/Images/CollegeLogo/MIT_LOGO.png"),
  chicagoUniversity: require("../Assets/Images/CollegeLogo/university_of_chicago_modern_etched_seal_1-svg.webp"),
  dukeUniversity: require("../Assets/Images/CollegeLogo/Duke_University.jpg"),
  jhUniversity: require("../Assets/Images/CollegeLogo/JH_University.jpg"),
  UCLA: require("../Assets/Images/CollegeLogo/UCLA.jpg"),
  Carnegie_Mellon: require("../Assets/Images/CollegeLogo/Carnegie_Mellon.jpg"),
  Northwestern: require("../Assets/Images/CollegeLogo/Northwestern_University.jpg"),
  cornell_University: require("../Assets/Images/CollegeLogo/cornell-university.jpg"),


  Browns: require("../Assets/Images/CollageLogoNew/Brown.png"),
  CarnegieMellonUniversity: require("../Assets/Images/CollageLogoNew/CarnegieMellonUniversity.png"),
  Chicago: require("../Assets/Images/CollageLogoNew/Chicago.png"),
  ColumbiaUniversity: require("../Assets/Images/CollageLogoNew/ColumbiaUniversity.png"),
  cornellUniversity: require("../Assets/Images/CollageLogoNew/cornelluniversity01.png"),
  cornellUniversity2: require("../Assets/Images/CollageLogoNew/cornelluniversity02.png"),
  DukeUniversity: require("../Assets/Images/CollageLogoNew/DukeUniversity.png"),
  Harvard: require("../Assets/Images/CollageLogoNew/Harvard.png"),
  JohnsHopkinsUniversity: require("../Assets/Images/CollageLogoNew/JohnsHopkinsUniversity.png"),
  MITUniversity: require("../Assets/Images/CollageLogoNew/MITUniversity.png"),
  NorthwesternUniversity: require("../Assets/Images/CollageLogoNew/NorthwesternUniversity.png"),
  Penn: require("../Assets/Images/CollageLogoNew/Penn.png"),
  Princeton: require("../Assets/Images/CollageLogoNew/Princeton.png"),
  Stanford: require("../Assets/Images/CollageLogoNew/Stanford.png"),
  UClA: require("../Assets/Images/CollageLogoNew/UClA.png"),
  Yale: require("../Assets/Images/CollageLogoNew/Yale.png"),
  cardBG: require("../Assets/Images/LandingPage/cardBG.png"),

  /***********************New Components Images*************************** */

  gradebg: require("../Assets/Images/NewComponentAllImages/Rectanglebg.jpg"),

};
export default localImages;
