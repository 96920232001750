import React from "react";
import localImages from "../../Constant/localImagesNew";
import "../../Assets/Css/SEOTemplate/gradeBanner.css"
import parser from "html-react-parser";

export default function GradeBanner({Item}) {
    const subject=JSON.parse(Item?.content)
    console.log("subject",subject)
    return (
        <div className="commonPad">
     { subject?.map((itm:any )=>{  return(<div className="gradebg mt-2">
            <h1 className="fw-bold">
               {itm.Heading}
            </h1>
            <div className="fs-5 fw-bold">
            {itm.SubHeading}
            </div>
            <div className="mt-2 gradetext fs-6 font28">
                <p className="gradediscription">
                   {parser(itm?.Description)}
                </p>
              
            </div>
        </div>)})}</div>
    );
}