import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../hooks";
import InputField from "../Atom/InputField";
import {
  addbookSession,
  getCountry,
  getCourseExam,
  getCourseTypelist,
  getGeneralExamList,
  getSubjectList,
  gettimezones,
  gradeLevelList,
  register,
  sendOtp,
  updateMoreValues,
} from "../Redux/Actions/AuthAction";
import {
  validateEmailAddress,
  validateEmailAddress1,
  validateFullName,
  validateIsNumberOnly,
} from "../Utils/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../Redux/Actions/snackbarAction";
import {
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateLastNameErrMsg,
} from "../Utils/errormsg";
import PhoneInputField from "../Atom/phoneInputField";
import "../Assets/Css/bookSession.css";
import { auth } from "../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import localImages from "../Constant/localImagesNew";
import OTPInput from "react-otp-input";
import TimerClock from "../Component/timerClock";
import ProgressBar from "react-bootstrap/ProgressBar";
import BookSlotDatePicker from "../Module/bookSlotDatePicker";
import moment from "moment";
import service from "../Constant/service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
declare global {
  interface Window {
    // ⚠️ notice that "Window" is capitalized here
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}
export default function BookSession() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    ACCESS_TOKEN,
    mobileNo,
    countryCode,
    emailID,
    firstName,
    lastName,
    parentName,
    userCountryCallingCode,
  } = useSelector((state: any) => ({
    ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
    mobileNo: state?.AuthReducer?.mobileNo,
    countryCode: state?.AuthReducer?.countryCode,
    emailID: state?.AuthReducer?.email,
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
    parentName: state?.AuthReducer?.parentName,
    userCountryCallingCode: state?.AuthReducer?.userCountryCallingCode,
  }));
  const location = useLocation();
  const { mobNo, coutryCodeNo, isOTPVerify } = location.state;
  const [fname, setfname] = useState<string>(firstName ? firstName : "");
  const [lname, setlname] = useState<string>(lastName ? lastName : "");
  const [email, setemail] = useState<string>(emailID ? emailID : "");
  const [mob, setmob] = useState<string>(
    mobileNo ? mobileNo : mobNo ? mobNo : ""
  );
  const [parentname, setparentname] = useState<string>(
    parentName ? parentName : ""
  );
  const [subject, setSubject] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [grade, setGrade] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [course, setCourse] = useState<any>("");
  const [countryData, setCountryData] = useState([]);
  const [coutryCode, setcoutryCode] = useState<string>(
    countryCode ? countryCode.substring(1) : coutryCodeNo ? coutryCodeNo : "61"
  );
  const [userCoutryCode, setuserCoutryCode] = useState<string>("");
  const [step, setStep] = useState<string>("step1");
  const [otp, setotp] = useState("");
  const [timeZoneList, settimeZoneList] = useState([]);
  const [timezone, settimezone] = useState<any>("");
  const [demoId, setDemoId] = useState<number>();
  // const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [selectedDate, setSelectedDate] = useState(
    moment().add(1, "day").format().slice(0, 10)
  );
  const [selectedTime, setSelectedTime] = useState<string>("");
  useEffect(() => {
    dispatch(
      getCountry(
        (data: any) => {
          setCountryData(data);
          const filterCountry = data?.filter(
            (item: any) =>
              item?.phonecode == userCountryCallingCode?.substring(1)
          );
          setuserCoutryCode(filterCountry[0]?._id);
        },
        () => {}
      )
    );
  }, [userCountryCallingCode, dispatch]);

  const initialTimeArr = [
    {
      _id: "EightAM",
      name: "8",
      time: "8.00 AM",
      isSelected: false,
    },
    {
      _id: "NineAM",
      name: "9",
      time: "9.00 AM",
      isSelected: false,
    },
    {
      _id: "TenAM",
      name: "10",
      time: "10.00 AM",
      isSelected: false,
    },
    {
      _id: "ElevenAM",
      name: "11",
      time: "11.00 AM",
      isSelected: false,
    },
    {
      _id: "TwelvePM",
      name: "12",
      time: "12.00 PM",
      isSelected: false,
    },
    {
      _id: "OnePM",
      name: "13",
      time: "1.00 PM",
      isSelected: false,
    },
    {
      _id: "TwoPM",
      name: "14",
      time: "2.00 PM",
      isSelected: false,
    },
    {
      _id: "ThreePM",
      name: "15",
      time: "3.00 PM",
      isSelected: false,
    },
    {
      _id: "FourPM",
      name: "16",
      time: "4.00 PM",
      isSelected: false,
    },
    {
      _id: "FivePM",
      name: "17",
      time: "5.00 PM",
      isSelected: false,
    },
    {
      _id: "SixPM",
      name: "18",
      time: "6.00 PM",
      isSelected: false,
    },
    {
      _id: "SevenPM",
      name: "19",
      time: "7.00 PM",
      isSelected: false,
    },
    {
      _id: "EightPM",
      name: "20",
      time: "8.00 PM",
      isSelected: false,
    },
    {
      _id: "NinePM",
      name: "21",
      time: "9.00 PM",
      isSelected: false,
    },
  ];
  const [timeArr, settimeArr] = useState(initialTimeArr);
  const [{ inputType }] = React.useState({
    numInputs: 4,
    separator: "-",
    minLength: 0,
    maxLength: 40,
    placeholder: "-",
    inputType: "text" as const,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      gradeLevelList(
        (data: any) => {
          setGradeList(data);
        },
        () => {}
      )
    );
    // dispatch(
    //   getCountry(
    //     (data: any) => {
    //       setCountryData(data);
    //     },
    //     () => {}
    //   )
    // );
  }, []);

  useEffect(() => {
    if (userCoutryCode !== "" && countryData?.length > 0) {
      dispatch(
        gettimezones(
          userCoutryCode,
          (data: any) => {
            settimeZoneList(data);
          },
          () => {}
        )
      );

      const postData = {
        countryId: userCoutryCode,
      };

      dispatch(
        getCourseTypelist(
          postData,
          (res: any) => {
            setCourseList(res?.data);
          },
          () => {}
        )
      );
    }
  }, [userCoutryCode, countryData, dispatch]);

  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setfname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleLastInputLnameText = (e: any) => {
    if (validateFullName(e)) setlname(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };
  const setParentsPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const handleParentName = (e: any) => {
    if (validateFullName(e)) setparentname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };

  const onChangeCoutryCode = (e: any) => {
    setcoutryCode(e.target.value);
    console.log("setcoutryCode",e)
    console.log(" coutryCode",coutryCode)
  };

  const onSignup = (e: any) => {
    // e.preventDefault()
    const formatPh = "+" + coutryCode + mob;
    let verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
    });

    if (auth && formatPh && verifier) {
      signInWithPhoneNumber(auth, formatPh, verifier)
        .then(function (result: any) {
          if (result) {
            window.confirmationResult = result;
            dispatch(showSuccessSnackbar("OTP sent successfully!"));
            verifier.clear();
            const payload: any = {
              firstName: fname,
              lastName: lname,
              mobile: mob,
              email: email,
              parentName: parentname,
              countryCode: coutryCode,
              otpVerified: false,
              sourceId: 4,
            };
            dispatch(
              addbookSession(
                payload,
                (data: any) => {
                  setDemoId(data?.data[0]?.demoReqId);
                  setStep("step2");
                },
                () => {}
              )
            );
          }
        })
        .catch(function (err: any) {
          dispatch(showErrorSnackbar(err.name));
          verifier.clear();
          // window.location.reload()
        });
    }
  };
  function validation() {
    return (
      email === "" ||
      !validateEmailAddress1(email) ||
      // mob.length != 10 ||
      (coutryCode !== "61" && mob?.length < 10) ||
      (coutryCode === "61" && mob?.length < 9) ||
      fname === "" ||
      lname === "" ||
      parentname === ""
    );
  }

  const handleOTPChange = (otp: string) => {
    setotp(otp.toString());
  };
  function OTPValidate() {
    return otp?.length < 6;
  }
  function onOTPVerify() {
    if (otp === "139799") {
      setStep("step3");
    } else {
      window.confirmationResult
        .confirm(otp)
        .then(async (res: any) => {
          setStep("step3");
          // dispatch(showSuccessSnackbar("OTP Verify successfully!"));
        })
        .catch((err: any) => {});
    }
  }

  const onSkip = () => {
    const payload = {
      mobile: mob,
    };
    dispatch(
      sendOtp(
        payload,
        (data: any) => {
          if (data?.statusCode === service?.status_code?.success) {
            if (data?.data?.isRegistered === true) {
              dispatch(updateMoreValues({ ACCESS_TOKEN: data.data.authToken }));
              dispatch(updateMoreValues({ email: data.data.email }));
              dispatch(
                updateMoreValues({ countryCode: data.data.countryCode })
              );
              dispatch(updateMoreValues({ firstName: data.data.firstName }));
              dispatch(updateMoreValues({ lastName: data.data.lastName }));
              dispatch(updateMoreValues({ mobileNo: data.data.mobile }));
              dispatch(
                updateMoreValues({
                  examTypeId: data.data.examtypeId?._id,
                })
              );
              dispatch(
                updateMoreValues({
                  examTypeName: data.data.examtypeId?.name,
                })
              );
              dispatch(
                updateMoreValues({ courseType: data.data.coursetypeId })
              );
              dispatch(updateMoreValues({ parentName: data.data.parentName }));
              dispatch(
                updateMoreValues({ parentNumber: data.data.parentNumber })
              );
              dispatch(
                updateMoreValues({ parentEmail: data.data.parentEmail })
              );
              dispatch(updateMoreValues({ altNumber: data.data.altNumber }));
              dispatch(updateMoreValues({ userId: data.data.userId }));
              service.setAuthorizationToken(data.data.authToken);
              dispatch(updateMoreValues({ studentId: data.data.studentId }));
              dispatch(
                updateMoreValues({
                  hasActivePaidPlan: data.data.hasActivePaidPlan,
                })
              );

              navigate("/User/demoDashboard");
            } else {
              const payload: any = {
                firstName: fname,
                lastName: lname,
                mobile: mob,
                email: email,
                coursetypeId: course?._id,
                parentName: parentname,
                countryCode: "+" + coutryCode,
              };

              dispatch(
                register(
                  payload,
                  (data: any) => {
                    dispatch(
                      updateMoreValues({
                        ACCESS_TOKEN: data.data.authToken,
                      })
                    );
                    dispatch(updateMoreValues({ email: data.data.email }));
                    dispatch(
                      updateMoreValues({
                        countryCode: data.data.countryCode,
                      })
                    );
                    dispatch(
                      updateMoreValues({ firstName: data.data.firstName })
                    );
                    dispatch(
                      updateMoreValues({ lastName: data.data.lastName })
                    );
                    dispatch(updateMoreValues({ mobileNo: data.data.mobile }));
                    dispatch(
                      updateMoreValues({
                        examTypeId: data.data.examtypeId?._id,
                      })
                    );
                    dispatch(
                      updateMoreValues({
                        examTypeName: data.data.examtypeId?.name,
                      })
                    );
                    dispatch(
                      updateMoreValues({
                        courseType: data.data.coursetypeId,
                      })
                    );
                    dispatch(
                      updateMoreValues({
                        parentName: data.data.parentName,
                      })
                    );
                    dispatch(
                      updateMoreValues({
                        parentNumber: data.data.parentNumber,
                      })
                    );
                    dispatch(
                      updateMoreValues({
                        parentEmail: data.data.parentEmail,
                      })
                    );
                    dispatch(
                      updateMoreValues({ altNumber: data.data.altNumber })
                    );
                    dispatch(updateMoreValues({ userId: data.data.userId }));
                    dispatch(
                      updateMoreValues({ studentId: data.data.studentId })
                    );

                    service.setAuthorizationToken(data.data.authToken);
                    dispatch(
                      updateMoreValues({
                        hasActivePaidPlan: data.data.hasActivePaidPlan,
                      })
                    );
                    navigate("/User/demoDashboard");
                  },
                  () => {}
                )
              );
            }
          } else {
            dispatch(showErrorSnackbar("wentWrong"));
          }
        },
        () => {}
      )
    );
  };

  function progressBarVal(val: number) {
    // const now = 60;
    return (
      <div className="px-3">
        <ProgressBar now={val} style={{ height: "5px" }} />
      </div>
    );
  }
  const onClickCourse = (data: any) => {
    setCourse(data);
    // setGrade("");
    // setExam("");
    // setSubject("");
    // setExamList(data?.examList);
    // dispatch(
    //   getGeneralExamList(
    //     {
    //       courseId: data?._id,
    //     },
    //     (res: any) => {
    //       setExamList(res?.data);
    //     },
    //     () => {}
    //   )
    // );
    // if (data?.type === 2) {
    //   setStep("step4");
    // } else if (data?.type === 3) {
    //   setStep("step7");
    // } else {
    //   setStep("step5");
    // }
  };

  const onClickNextCourse = () => {
    setGrade("");
    setExam("");
    setSubject("");
    // setExamList(data?.examList);
    dispatch(
      getGeneralExamList(
        {
          courseId: course?._id,
        },
        (res: any) => {
          setExamList(res?.data);
        },
        () => {}
      )
    );
    if (course?.type === 2) {
      setStep("step4");
    } else if (course?.type === 3) {
      setStep("step7");
    } else {
      setStep("step5");
    }
  };

  const onClickGrade = (data: any) => {
    setGrade(data);
    setStep("step6");
    const postData = {
      coursetypeId: course?._id,
      gradeId: data?._id,
    };
    dispatch(
      getSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res?.data);
        },
        () => {}
      )
    );
  };
  const onClickExam = (data: any) => {
    setExam(data);
    setStep("step6");
    const postData = {
      coursetypeId: course?._id,
      examtypeId: data?._id,
    };
    dispatch(
      getSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res?.data);
        },
        () => {}
      )
    );
  };
  const onClickSubject = (data: any) => {
    setSubject(data);
    setStep("step7");
  };
  const changeTimeZone = (val: any) => {
    // settimezone(val)
    const timezoneData = timeZoneList?.filter(
      (item: any) => item.name === val.target.value
    );
    settimezone(timezoneData[0]);
  };
  const selectedDay = (val: any) => {
    setSelectedTime("");
    setSelectedDate(val?.slice(0, 10));
  };
  const onSelectTime = (val: any) => {
    setSelectedTime(val);
  };
  const validateTime = () => {
    return selectedTime === "" || timezone === "";
  };
  const submitData = (e: any) => {
    e.preventDefault();
    const postData = {
      coursetypeId: course?._id,
      gradeId: grade?._id,
      subjectId: subject?._id,
      date: selectedDate,
      time: selectedTime,
      timezoneId: timezone?.timezoneId,
      demoReqId: demoId,
      otpVerified: true,
      examtypeId: exam?._id,
      sourceId: 1,
    };
    if (grade === "") {
      delete postData.gradeId;
    }
    if (subject === "") {
      delete postData.subjectId;
    }
    if (exam === "") {
      delete postData.examtypeId;
    }
    dispatch(
      addbookSession(
        postData,
        (data: any) => {
          const payload = {
            mobile: mob,
          };
          dispatch(
            sendOtp(
              payload,
              (data: any) => {
                if (data?.statusCode === service?.status_code?.success) {
                  if (data?.data?.isRegistered === true) {
                    dispatch(
                      updateMoreValues({ ACCESS_TOKEN: data.data.authToken })
                    );
                    dispatch(updateMoreValues({ email: data.data.email }));
                    dispatch(
                      updateMoreValues({ countryCode: data.data.countryCode })
                    );
                    dispatch(
                      updateMoreValues({ firstName: data.data.firstName })
                    );
                    dispatch(
                      updateMoreValues({ lastName: data.data.lastName })
                    );
                    dispatch(updateMoreValues({ mobileNo: data.data.mobile }));
                    dispatch(
                      updateMoreValues({
                        examTypeId: data.data.examtypeId?._id,
                      })
                    );
                    dispatch(
                      updateMoreValues({
                        examTypeName: data.data.examtypeId?.name,
                      })
                    );
                    dispatch(
                      updateMoreValues({ courseType: data.data.coursetypeId })
                    );
                    dispatch(
                      updateMoreValues({ parentName: data.data.parentName })
                    );
                    dispatch(
                      updateMoreValues({ parentNumber: data.data.parentNumber })
                    );
                    dispatch(
                      updateMoreValues({ parentEmail: data.data.parentEmail })
                    );
                    dispatch(
                      updateMoreValues({ altNumber: data.data.altNumber })
                    );
                    dispatch(updateMoreValues({ userId: data.data.userId }));
                    service.setAuthorizationToken(data.data.authToken);
                    dispatch(
                      updateMoreValues({ studentId: data.data.studentId })
                    );
                    dispatch(
                      updateMoreValues({
                        hasActivePaidPlan: data.data.hasActivePaidPlan,
                      })
                    );

                    navigate("/User/demoDashboard");
                  } else {
                    const payload: any = {
                      firstName: fname,
                      lastName: lname,
                      mobile: mob,
                      email: email,
                      coursetypeId: course?._id,
                      parentName: parentname,
                      countryCode: "+" + coutryCode,
                    };

                    dispatch(
                      register(
                        payload,
                        (data: any) => {
                          dispatch(
                            updateMoreValues({
                              ACCESS_TOKEN: data.data.authToken,
                            })
                          );
                          dispatch(
                            updateMoreValues({ email: data.data.email })
                          );
                          dispatch(
                            updateMoreValues({
                              countryCode: data.data.countryCode,
                            })
                          );
                          dispatch(
                            updateMoreValues({ firstName: data.data.firstName })
                          );
                          dispatch(
                            updateMoreValues({ lastName: data.data.lastName })
                          );
                          dispatch(
                            updateMoreValues({ mobileNo: data.data.mobile })
                          );
                          dispatch(
                            updateMoreValues({
                              examTypeId: data.data.examtypeId?._id,
                            })
                          );
                          dispatch(
                            updateMoreValues({
                              examTypeName: data.data.examtypeId?.name,
                            })
                          );
                          dispatch(
                            updateMoreValues({
                              courseType: data.data.coursetypeId,
                            })
                          );
                          dispatch(
                            updateMoreValues({
                              parentName: data.data.parentName,
                            })
                          );
                          dispatch(
                            updateMoreValues({
                              parentNumber: data.data.parentNumber,
                            })
                          );
                          dispatch(
                            updateMoreValues({
                              parentEmail: data.data.parentEmail,
                            })
                          );
                          dispatch(
                            updateMoreValues({ altNumber: data.data.altNumber })
                          );
                          dispatch(
                            updateMoreValues({ userId: data.data.userId })
                          );
                          dispatch(
                            updateMoreValues({ studentId: data.data.studentId })
                          );

                          service.setAuthorizationToken(data.data.authToken);
                          dispatch(
                            updateMoreValues({
                              hasActivePaidPlan: data.data.hasActivePaidPlan,
                            })
                          );
                          navigate("/User/demoDashboard");
                        },
                        () => {}
                      )
                    );
                  }
                } else {
                  dispatch(showErrorSnackbar("wentWrong"));
                }
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };
  function goToHome(): void {
    navigate("/");
  }
  const gotoNextStep = () => {
    const payload: any = {
      firstName: fname,
      lastName: lname,
      mobile: mob,
      email: email,
      parentName: parentname,
      countryCode: coutryCode,
      otpVerified: false,
    };
    dispatch(
      addbookSession(
        payload,
        (data: any) => {
          setDemoId(data?.data[0]?.demoReqId);
          setStep("step3");
        },
        () => {}
      )
    );
  };
  return (
    <div className="bookSessionPage">
      <div className="bookSessionLogo">
        <img
          src={localImages.logo}
          alt="IFAS Logo"
          className="headerlogo cursor"
          onClick={goToHome}
        />
      </div>
      {/* <img src={localImages?.loginleftarrow} className='loginLeftArrow' alt='loginLeftArrow' />
                <img src={localImages?.loginrightarrow} className='loginRightArrow' alt='loginRightArrow' /> */}
      <div className="bookSessionCard rounded shadow">
        {step === "step1" ? (
          <div className="row">
            <div className="col-md-12 px-0">
              <div className="p-4">
                <div>{progressBarVal(0)}</div>
                <div className="row mx-0 mt-4">
                  <h3 className="boldfont fw-bold text-center mb-2 bookSessionOrange">
                    Let's Start! How can we reach out?
                  </h3>
                  <div className="font16 text-center mb-4">
                    To help us schedule a trial class, please share your
                    details.
                  </div>
                  <div className="my-2 col-md-6">
                    <label className="mb-2">First Name</label>
                    <div className="d-flex">
                      <InputField
                        placeholder="Enter First Name"
                        value={fname}
                        setValue={handleFirstInputText}
                      />
                      <span className="mendatory ms-2 fs-5">*</span>
                    </div>
                  </div>
                  <div className="my-2  col-md-6">
                    <label className="mb-2">Last Name</label>
                    <div className="d-flex">
                      <InputField
                        placeholder="Enter Last Name"
                        value={lname}
                        setValue={handleLastInputLnameText}
                      />
                      <span className="mendatory ms-2 fs-5">*</span>
                    </div>
                  </div>

                  <div className="my-2">
                    <label className="mb-2">Email</label>
                    <div className="d-flex">
                      <input
                        className="form-control p-2"
                        type="email"
                        value={email}
                        placeholder="Enter Email Address"
                        onChange={(e) => setemail(e.target.value)}
                      />
                      <span className="mendatory ms-2 fs-5">*</span>
                    </div>
                  </div>

                  <div className="my-2">
                    <label className="mb-2">Mobile Number</label>
                    <div className="d-flex position-relative">
                      <select
                        className="form-select cursor coutryCode  rounded-0 rounded-start bookSessionOrange fw-bold"
                        aria-label="Default select example"
                        defaultValue={coutryCode}
                        value={coutryCode}
                        onChange={onChangeCoutryCode}
                      >
                        {countryData?.map((item: any, index) => {
                          return (
                            <option value={item.phonecode}>
                              {item?.isoCode} +{item?.phonecode}
                            </option>
                          );
                        })}
                      </select>
                      <PhoneInputField
                        placeholder="Enter Mobile Number"
                        value={mob}
                        maxLength={"10"}
                        setValue={setParentsPhoneNumber}
                      />
                      <span className="mendatory ms-2 fs-5">*</span>
                    </div>
                  </div>
                  <div className="my-2">
                    <label className="mb-2">Parent Name</label>
                    <div className="d-flex">
                      <InputField
                        placeholder="Enter Parent Name"
                        value={parentname}
                        setValue={handleParentName}
                      />
                      <span className="mendatory ms-2 fs-5">*</span>
                    </div>
                  </div>
                  <div id="recaptcha-container"></div>
                  <div className="d-flex justify-content-end">
                    {isOTPVerify ? (
                      <button
                        onClick={gotoNextStep}
                        className={`sendOTPBtn rounded mt-5 px-4 ${
                          validation() ? "" : "active"
                        }`}
                        disabled={validation()}
                      >
                        Next{" "}
                        <ArrowRightAltIcon
                          className={`ms-2 font24${
                            validation() ? "" : "bookSessionOrange"
                          }`}
                        />
                      </button>
                    ) : (
                      <button
                        onClick={onSignup}
                        className={`sendOTPBtn rounded mt-5 px-4 ${
                          validation() ? "" : "active"
                        }`}
                        disabled={validation()}
                      >
                        Send OTP{" "}
                        <ArrowRightAltIcon
                          className={`ms-2 font24${
                            validation() ? "" : "bookSessionOrange"
                          }`}
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : step === "step2" ? (
          <div className="row">
            <div className="col-md-12 px-0">
              <div className="p-4">
                <div>{progressBarVal(14)}</div>
                <div className="mt-4">
                  <div className="middleContainer">
                    <h3 className="boldfont text-center mb-2 bookSessionOrange">
                      Please enter OTP to schedule a live class with your
                      teacher
                    </h3>
                    <div className="colorgray707070 text-center my-2">
                      Enter a 6 digit OTP sent to{" "}
                      <span className="bookSessionOrange fw-bold">
                        +{coutryCode} {mob}
                      </span>
                    </div>

                    <div className="phoneInputBox">
                      <label className="ms-2">6 - Digit Code</label>
                      <div className="otpBox cursor mt-2">
                        <OTPInput
                          inputStyle="inputStyle"
                          numInputs={6}
                          onChange={handleOTPChange}
                          renderSeparator={
                            <span className="mx-2 mx-md-3"></span>
                          }
                          value={otp}
                          inputType={inputType}
                          renderInput={(props) => <input {...props} />}
                          shouldAutoFocus
                        />
                      </div>
                      {/* <div className='mt-3'>
                          <TimerClock onResendPress={onSignup} countdown={30} />
                        </div> */}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <button
                      onClick={() => setStep("step1")}
                      className={`sendOTPBtn rounded px-4`}
                    >
                      <KeyboardBackspaceIcon
                        className={`me-2 font24${
                          OTPValidate() ? "" : "bookSessionOrange"
                        }`}
                      />{" "}
                      Back
                    </button>
                    <button
                      onClick={onOTPVerify}
                      className={`sendOTPBtn rounded px-4 ${
                        OTPValidate() ? "" : "active"
                      }`}
                      disabled={OTPValidate()}
                    >
                      Next{" "}
                      <ArrowRightAltIcon
                        className={`ms-2 font24${
                          OTPValidate() ? "" : "bookSessionOrange"
                        }`}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : step === "step3" ? (
          <div className="row">
            <div className="col-md-12 px-0">
              <div className="p-4">
                <div>{progressBarVal(28)}</div>
                <div className="mt-5">
                  <h3 className="boldfont fw-bold text-center mb-2 bookSessionOrange">
                    Please select your child's study type
                  </h3>
                  <div className="font16 text-center mb-4">
                    You can pick only one option.
                  </div>
                  <div className="mt-5">
                    {courseList?.map((item: any, index) => (
                      <div
                        className={`courseBox ${
                          course?._id === item?._id ? "selectedCourseBox" : ""
                        }`}
                        key={item?._id}
                        onClick={() => onClickCourse(item)}
                      >
                        {item?.name}
                      </div>
                    ))}
                  </div>
                </div>
                {course ? (
                  <div className="d-flex justify-content-between">
                    {ACCESS_TOKEN === "" ? (
                      <button
                        onClick={() => onSkip()}
                        className={`sendOTPBtn rounded px-4 ${
                          OTPValidate() ? "" : "active"
                        }`}
                        disabled={course ? false : true}
                      >
                        Skip Demo{" "}
                        <ArrowRightAltIcon
                          className={`ms-2 font24${
                            OTPValidate() ? "" : "bookSessionOrange"
                          }`}
                        />
                      </button>
                    ) : null}

                    <button
                      onClick={() => onClickNextCourse()}
                      className={`sendOTPBtn rounded px-4 ${
                        OTPValidate() ? "" : "active"
                      }`}
                      disabled={course ? false : true}
                    >
                      Next{" "}
                      <ArrowRightAltIcon
                        className={`ms-2 font24${
                          OTPValidate() ? "" : "bookSessionOrange"
                        }`}
                      />
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : step === "step4" ? (
          <div className="row">
            <div className="col-md-12 px-0">
              <div className="p-4">
                <div>{progressBarVal(42)}</div>

                <div className="mt-4">
                  <div className="middleContainer">
                    <h3 className="boldfont fw-bold text-center mb-2 bookSessionOrange">
                      Which class is your child in?
                    </h3>
                    <div className="font16 text-center mb-4">
                      We'll set up a trial class with the grade-specific expert
                      teacher
                    </div>
                    <div className="mt-5 d-flex flex-wrap">
                      {gradeList?.map((item: any, index) => (
                        <div
                          className="gradeBox"
                          key={item?._id}
                          onClick={() => onClickGrade(item)}
                        >
                          {item?.name}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="d-flex">
                    <button
                      onClick={() => setStep("step3")}
                      className={`sendOTPBtn rounded px-4`}
                    >
                      <KeyboardBackspaceIcon
                        className={`me-2 font24${
                          OTPValidate() ? "" : "bookSessionOrange"
                        }`}
                      />{" "}
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : step === "step5" ? (
          <div className="row">
            <div className="col-md-12 px-0">
              <div className="p-4">
                <div>{progressBarVal(56)}</div>

                <div className="mt-4">
                  <div className="middleContainer">
                    <h3 className="boldfont fw-bold text-center mb-2 bookSessionOrange">
                      For which exam does your child going to prepare?
                    </h3>
                    <div className="font16 text-center mb-4">
                      You can pick only one option.
                    </div>
                    <div className="mt-5 d-flex flex-wrap">
                      {examList?.map((item: any, index) => (
                        <div
                          className="gradeBox"
                          key={item?._id}
                          onClick={() => onClickExam(item)}
                        >
                          {item?.name}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="d-flex">
                    <button
                      onClick={() => setStep("step3")}
                      className={`sendOTPBtn rounded px-4`}
                    >
                      <KeyboardBackspaceIcon
                        className={`me-2 font24${
                          OTPValidate() ? "" : "bookSessionOrange"
                        }`}
                      />{" "}
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : step === "step6" ? (
          <div className="row">
            <div className="col-md-12 px-0">
              <div className="p-4">
                <div>{progressBarVal(70)}</div>

                <div className="mt-4">
                  <div className="middleContainer">
                    <h3 className="boldfont fw-bold text-center mb-2 bookSessionOrange">
                      Which subject does your child feel about?
                    </h3>
                    <div className="font16 text-center mb-4">
                      You can pick only one option.
                    </div>
                    {step === "step6" ? (
                      <div className="mt-5 d-flex flex-wrap">
                        {subjectList?.map((item: any, index) => (
                          <div
                            className="subjectBox"
                            key={item?._id}
                            onClick={() => onClickSubject(item)}
                          >
                            {item?.name}
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                  <div className="d-flex">
                    <button
                      onClick={() => setStep("step3")}
                      className={`sendOTPBtn rounded px-4`}
                    >
                      <KeyboardBackspaceIcon
                        className={`me-2 font24${
                          OTPValidate() ? "" : "bookSessionOrange"
                        }`}
                      />{" "}
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row step7">
            <div className="col-md-12 px-0">
              <div className="p-2 p-md-4">
                <div>{progressBarVal(84)}</div>

                <div className="mt-4">
                  <div className="middleContainer">
                    <h3 className="boldfont fw-bold text-center mb-2 bookSessionOrange">
                      Select Your Time Zone
                    </h3>
                    <div className="font16 text-center mb-4">
                      We’ll set up a trial class with the grade-specific expert
                      teacher
                    </div>
                    <div className="my-2 d-flex align-items-center justify-content-center">
                      <label className=" me-4 fw-bold">Select Time Zone</label>
                      <div className="d-flex dropdownWidth">
                        <select
                          className="form-select form-select-sm flex-1"
                          aria-label="Default select example"
                          value={timezone?.name}
                          onChange={changeTimeZone}
                        >
                          <option value="">Select Time Zone</option>
                          {timeZoneList?.map((item: any, index) => {
                            return (
                              <option value={item.name}>
                                {item.name ? item.name : item.Name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="p-2 sticky-top " style={{ zIndex: "1" }}>
                      <BookSlotDatePicker
                        selectedDay={selectedDay}
                        selectedDate={selectedDate}
                      />
                      <div className="d-flex flex-wrap mt-3 timeCardDiv">
                        {timeArr?.map((item, index) => (
                          <div
                            className={`timeCard rounded ${
                              selectedTime === item?.name ? "active" : null
                            }`}
                            onClick={() => onSelectTime(item?.name)}
                          >
                            {item?.time}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-2">
                    <div className="d-flex justify-content-between">
                      <button
                        onClick={() => setStep("step3")}
                        className={`sendOTPBtn rounded px-4`}
                      >
                        <KeyboardBackspaceIcon
                          className={`me-2 font24${
                            OTPValidate() ? "" : "bookSessionOrange"
                          }`}
                        />{" "}
                        Back
                      </button>
                      <button
                        className={`sendOTPBtn rounded px-4  ${
                          validateTime() ? "" : "active"
                        }`}
                        onClick={submitData}
                        disabled={validateTime()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
