import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Assets/Css/thankYou.css";
import localImages from "../Constant/localImages";

const ThankYou = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pagename } = location.state;
  const clickHome = () => {
    if (
      pagename === "CollegeCounseling" ||
      pagename === "DigitalSAT" ||
      pagename === "LearnLandingForm" ||
      pagename === "ACT" ||
      pagename === "LSAT"
    ) {
      navigate("/User/demoDashboard");
    }
  };

  return (
    <>
      <div className="thank-you-message">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div>
                    <img
                      src={localImages?.logo}
                      alt="customer-execuitv"
                      className="lurnigologo"
                    ></img>
                  </div>
                  <div className=" col-sm-12 col-md-6">
                    <div className="text-center">
                      <img
                        src={localImages?.thanks}
                        alt="customer-execuitv"
                        className="img-fluid"
                      ></img>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 text-holder">
                    <h1 className="col-md-12 font48 fw-bold">Thank You!</h1>
                    <p className="mb-1 col-md-12 font24">
                      Your Submission has been Received..
                    </p>
                    <p className="col-md-12 font16">
                      We will be in touch with you soon..
                    </p>
                    <button
                      type="button"
                      className="rounded clickbtn mt-md-2 mt-sm-1 px-4 col-md-12"
                      onClick={clickHome}
                    >
                      Start Your Journey With LurniGo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
