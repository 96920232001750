import React, { useEffect, useState } from "react";
import localImages from "../../Constant/localImagesNew";
import parser from "html-react-parser"; 
import "../../Assets/Css/SEOTemplate/topicSection.css"
import dataConstant from "../../Constant/dataConstant";
import HistoryIcon from '@mui/icons-material/History';
function TopicSection({Item}) {
  console.log("Item",Item)
  //  const TopicSection = JSON.parse(Item.content); 
  const [TopicSection, setTopicSection] = useState<any>(JSON.parse(Item.content)); 
  console.log("TopicSection",TopicSection)
  const [programData, setProgramData] = useState<any>(""); 
  useEffect(()=>{
    if(TopicSection?.topicArr?.length>0){
      setProgramData(TopicSection?.topicArr[0])
    }
  },[TopicSection])
  const onChangeProgram = (val: any) => { 
    setProgramData(val);
  };
  console.log("programData",programData)
  return (
    <div className=" ">
      <div className="commonPad position-relative bgpink">
        <h1 className="headingFont text-center">{TopicSection?.Heading}</h1>
        <div className="sub-heading">{TopicSection?.SubHeading}</div>
        <div className="d-none d-sm-block ">
          <div className="row  d-flex justify-content-center mt-5 ">
        
            <div className="col-md-5 col-12">
              {TopicSection?.topicArr?.map(
                (c: any, index: any) => {
                  return (
                    <div className=" mt-2 topicCard">
                      <div
                        className={`topicSectionData  ${
                          c?._id === programData?._id ? "active" : ""
                        }`}
                        onClick={() => onChangeProgram(c)}
                      >
                        <p className="font20 fw-bold mb-0">{c?.topicName}</p>
                        <div className="d-flex justify-content-between"> 
                       <div className="col-md-10">Add on:{parser(c?.addOns)}</div>

                        </div>
                        <div className="d-flex justify-content-between"> 
                          <div className="col-md-1"><HistoryIcon/></div>
                          <div className="col-md-11">1.5 month</div>

                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            <div className="col-md-7 p-3 pt-0 mt-2 pb-0">
              <div className="p-2 pb-0 bg-light rounded h-100  ">
                
                <div className="px-4 border-bottom">
                  <p className="fw-bolder font20 mb-0">Summery</p>
                  <ul className="mt-4" style={{ listStyle: "disc" }}>
                    <li className="text-dark font16 ">
                      {programData?.desc1}
                    </li>
                    <li className="text-dark font16 ">
                      {programData?.desc2}
                    </li>
                    <li className="text-dark font16 ">
                      {programData?.desc3}
                    </li>
                  </ul>
                </div>
                <div className="d-flex justify-content-between topicDiv ">
            {programData?.lectureArr?.map((c:any,index:any)=>{ return( <div className="col-md-6 p-2 px-4">
                  <p className="fw-bolder font20 mb-0">{c?.lectureName}</p>
                  {/* <ul className="mt-4" style={{ listStyle: "disc" }}>
                    <li className="text-dark font16 ">
                   
                    </li>
                     
                 
                   
                  </ul>  */}
                  <div> 
                   {(c?.Title)}
                    </div>
                  </div>)})}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
}

export default TopicSection;
