import React, { useEffect, useRef, useState } from "react";
import localImages from "../Constant/localImagesNew";
import localImage from "../Constant/localImages";
import Faq from "../Component/faq";
import Footer from "../Component/footer";
import { useNavigate } from "react-router-dom";
import HeaderHome from "../Component/headerHome";
import Sidebar from "../Module/sidebar";
import { useSelector } from "react-redux";
import {
  SidebarDataFreeUser,
  SidebarDataPaidUser,
} from "../Module/sidebarData";
import BookSession from "../Module/bookSession";
import StudentSayCarousel from "../Component/studentSayCarousel";
import AcademicSubjectCard from "../Module/academicSubjectCard";
import "../Assets/Css/collegeConsulting.css";
import dataConstant from "../Constant/dataConstant";
import "../Assets/Css/customTable.css";
import TalkToAdvisorPopup from "../Component/talkToAdvisorPopup";
import LandingPageFooter from "../Component/landingPageFooter";
import CollegeCouncellingPopup from "../Component/collegeCouncellingPopup";
import { getCountry } from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import PhoneInputField from "../Atom/phoneInputField";
import CloseIcon from "@mui/icons-material/Close";
import { validateIsNumberOnly } from "../Utils/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../Utils/errormsg";
import BookDemoSessionPopup from "../Component/bookDemoSessionPopUp";

export default function CollegeConsulting() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const targetRef: any = useRef(null);

  const [openTalkToAdvisor, setopenTalkToAdvisor] = useState<boolean>(false);
  const [coutryCode, setcoutryCode] = useState<string>("1");
  const [countryData, setCountryData] = useState([]);
  const [closePopup, setClosePopup] = useState<boolean>(false);
  const [openpopup1, setopenpopup1] = useState<boolean>(false);
  const [mob, setmob] = useState<string>("");
  const [popupShown, setPopupShown] = useState(false); // To track if the popup has been shown before

  const [isHidden, setisHidden] = useState<boolean>(true);

  var myScrollFunc = function () {
    // let offsetHeight = stageCanvasRef.current.offsetHeight;
    var y = window.scrollY;
    const maxScroll = document.body.scrollHeight - window.innerHeight;
    if (y >= 300 && y < maxScroll - 300) {
      setisHidden(false);
    } else {
      setisHidden(true);
    }
  };
  window.addEventListener("scroll", myScrollFunc);

  const closesTalkToAdvisor = () => {
    setopenTalkToAdvisor(false);
  };
  const { ACCESS_TOKEN, hasActivePaidPlan } = useSelector((state: any) => ({
    ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
  }));
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getCountry(
        (data: any) => {
          setCountryData(data);
        },
        () => {}
      )
    );
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !popupShown) {
          setopenpopup1(true);
          setPopupShown(true);
        }
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [popupShown]);

  const contactUS = (val: string) => {
    const element = document.getElementById(val);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };
  const onChangeCoutryCode = (e: any) => {
    setcoutryCode(e.target.value);
  };
  const setPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const featData = [
    {
      imgPath: localImages?.classwork,
      title: "Classwork Help",
      desc: "Navigate the complexities of class assignments with ease using Lurnigo's Classwork Help feature. Our dedicated team of educators is ready to assist you, ensuring that you not only understand the material but also excel in your classwork.",
    },
    {
      imgPath: localImages?.advLearn,
      title: "Advanced Learning",
      desc: "Elevate your learning experience with Lurnigo's Advanced Learning feature. Dive deeper into subjects with challenging yet engaging content designed to stimulate critical thinking and foster a love for learning.",
    },
    {
      imgPath: localImages?.awardWinning,
      title: "Award Winning Curriculum",
      desc: "Experience education at its finest with Lurnigo's Award-Winning Curriculum. Our carefully crafted curriculum, recognized for its innovation and effectiveness, ensures that you receive a world-class education.",
    },
    {
      imgPath: localImages?.classwork,
      title: "Classwork Help",
      desc: "Navigate the complexities of class assignments with ease using Lurnigo's Classwork Help feature. Our dedicated team of educators is ready to assist you, ensuring that you not only understand the material but also excel in your classwork.",
    },
    {
      imgPath: localImages?.advLearn,
      title: "Advanced Learning",
      desc: "Elevate your learning experience with Lurnigo's Advanced Learning feature. Dive deeper into subjects with challenging yet engaging content designed to stimulate critical thinking and foster a love for learning.",
    },
    {
      imgPath: localImages?.awardWinning,
      title: "Award Winning Curriculum",
      desc: "Experience education at its finest with Lurnigo's Award-Winning Curriculum. Our carefully crafted curriculum, recognized for its innovation and effectiveness, ensures that you receive a world-class education.",
    },
  ];

  function goToLink(val: string): void {
    if (val === "BookSession") {
      navigate("/" + val, {
        state: {
          mobNo: "",
          coutryCodeNo: "",
          isOTPVerify: ACCESS_TOKEN ? true : false,
        },
      });
    } else {
      navigate("/" + val);
    }
  }
  return (
    <div>
      <HeaderHome isHidden={true} />
      <div className="d-block d-md-none">
        <Sidebar
          data={
            hasActivePaidPlan === true
              ? SidebarDataPaidUser
              : SidebarDataFreeUser
          }
        />
      </div>
      {/*************Banner************* */}
      <div className="position-relative collegeConsultingContainer">
        <img
          src={localImages?.ccLeftArrow}
          className="ccLeftArrow"
          alt="learnSolleft"
        />
        <img
          src={localImages?.ccRightArrow}
          className="ccRightArrow"
          alt="learnSolright"
        />
        <div className="width800 mx-auto px-4" ref={targetRef}>
          <h1 className="headingFont text-center">
            The #1 rated college admissions consulting group
          </h1>
          <div className="font16 mt-4 text-center px-4">
            Trusted by thousands of families, Lurnigo is the #1 rated college
            admissions consulting group. Our team of former college admissions
            officers provides 1-on-1 guidance and support that enables students
            to realize their full potential. Whether planning ahead in grade 9
            and 10 or navigating the admissions process in grade 11 and 12,
            Lurnigo will help you achieve success.
          </div>
          <div className="mt-5 text-center">
            <button
              className="signBtn px-4 fw-bold font16"
              onClick={() => setopenTalkToAdvisor(true)}
            >
              Talk to an advisor Today
            </button>
          </div>
        </div>
      </div>
      <div className="commonPad pb-0">
        <video muted autoPlay loop className="videotag m-auto border rounded">
          <source src={localImages?.CollegeConsulting} type="video/mp4" />
        </video>

        <div className="mt-5 w-100 py-5 stateDiv">
          {dataConstant?.statData?.map((item, index) => (
            <div className="stateBox text-center">
              {/* <div className='statNo'><CountUp duration={25} className="counter" end={item?.count} />+</div> */}
              <div className="statNo">{item?.count}</div>
              <div className="statHeading">{item?.heading}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="commonPad">
        <h1 className="headingFont width800 mx-auto text-center">
          How Lurnigo's Class of 2027
        </h1>
        <h1 className="headingFont width800 mx-auto text-center">
          results compare!
        </h1>
        <div className="mt-5">
          <table className="table customTable ">
            <thead>
              <tr>
                <th scope="col">University</th>
                <th scope="col">General Admission Rate</th>
                <th scope="col">LurniGo student Admission Rate</th>
              </tr>
            </thead>
            <tbody className="border">
              {dataConstant?.universityTable?.map((item) => (
                <tr>
                  <td>{item?.universityname}</td>
                  <td>{item?.generalRate}</td>
                  <td>{item?.lurnigoRate}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className="commonPad">
      <h1 className="headingFont width900 mx-auto text-center">
      Step by step process of LurniGo College 
        </h1>
        <h1 className="headingFont width900 mx-auto text-center">
        Admission Consulting Validation
        </h1>
       <div className="d-flex">
        <div className="col-md-6">

        </div>
        <div className="col-md-6 stepbystepProcedureDiv ps-5">
          <img src={localImage?.collegeconsultingimg} className=" img-fluid "></img>
        </div>

       </div>
      </div> */}

      <div className="commonPad bgBlue getDream position-relative overflow-hidden">
        <img
          src={localImages?.collegePredleft}
          className="getDreamleft"
          alt="collegePredleft"
        />
        <img
          src={localImages?.collegePredright}
          className="getDreamright"
          alt="collegePredright"
        />

        <div className="width800 mx-auto mt-5 mb-5 text-center" >
          <h1 className="headingFont text-center mb-4">
            Get into your dream university today!
          </h1>
          <div className="mb-5 font16 text-center"
        >
            Unlock Your Future: Dream, Apply, Achieve!
          </div>
          <div className="text-center my-4">
            <button className="exploreBtn px-4 border">Explore Programs</button>
          </div>
        </div>
      </div>
      <div className="commonPad">
        <div className="row learnSolTab flex-wrap" ref={targetRef}>
          {featData?.map((item, index) => (
            <div
              className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3"
              data-wow-duration="2s"
              data-wow-delay={`${index * 0.5}s`}
              key={index}
            >
              <AcademicSubjectCard data={item} index={index} />
            </div>
          ))}
        </div>
      </div>
      <div className="commonPad bgLightGrey">
        <div className="width800 mx-auto my-5">
          <h1 className="headingFont text-center">
            Don't just take our word for it
          </h1>
          <div className="bannersubtext mb-3 text-center">
            Hear what our students have to say
          </div>
        </div>
        <div>
          <StudentSayCarousel
            Data={dataConstant?.studentSayData}
            id="carouselStudent"
          />
        </div>
        <div></div>
      </div>

      <div className="commonPad ">
        <div className="row mx-0">
          <div className="col-md-3">
            <h1 className="headingFont mb-4">FAQs</h1>
            <div className="mb-4">
              <button
                className="contactUSbtn px-5"
                onClick={() => contactUS("Footer")}
              >
                Contact Us
              </button>
            </div>
          </div>
          <div className="col-md-9">
            <Faq faqData={dataConstant?.faqData} id={"HomeFAQ"} />
          </div>
        </div>
      </div>

      <div className="commonPad ">
        <BookSession clickEvent={() => goToLink("BookSession")} />
      </div>
      {/***************Footer*************** */}
      <Footer id="Footer" />
      <TalkToAdvisorPopup
        open={openTalkToAdvisor}
        handleClose={closesTalkToAdvisor}
      />

      <BookDemoSessionPopup
        open={openpopup1}
        handleClose={() => setopenpopup1(false)}
      />
      {!isHidden ? (
        <div className="fixedFooter">
          <div className="row mx-0">
            <div className="col-7 col-md-6 d-flex align-items-center mb-2 mb-md-0">
              <div className="fw-bold font20 colorblue fontLibre">
                Book Your Trial Class With Us Today!
              </div>
            </div>
            <div className="col-5 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end flex-wrap position-relative">
              <button
                className="bookSessionButton rounded fw-bold mt-2 mt-md-0 font16"
                onClick={() => goToLink("BookSession")}
              >
                Book Your Trial Session
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
