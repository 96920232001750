import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import localImages from "../Constant/localImagesNew";
import InputField from "../Atom/InputField";
import Dropdown from "./dropdown";
import {
  addDSATlandingpage,
  getCountry,
  gradeLevelList,
  sendOtp,
  updateMoreValues,
} from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../Redux/Actions/snackbarAction";
import {
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateLastNameErrMsg,
} from "../Utils/errormsg";
import {
  validateEmailAddress,
  validateEmailAddress1,
  validateFullName,
  validateIsNumberOnly,
} from "../Utils/validators";
import PhoneInputField from "../Atom/phoneInputField";
import OTPInput from "react-otp-input";
import { auth } from "../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import service from "../Constant/service";
import { useNavigate, useParams } from "react-router-dom";

interface props {
  open: boolean;
  handleClose: Function;
  countryCode: string;
  mob: string;
}
export default function CollegeCouncellingPopup({
  open,
  handleClose,
  mob,
  countryCode,
}: props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [firstName1, setfirstname1] = useState<string>("");
  const [lastName1, setlastName1] = useState<string>("");
  const [email1, setemail1] = useState<string>("");
  const [mob1, setmob1] = useState<string>("");
  const [countryData1, setCountryData1] = useState([]);
  const [coutryCode1, setcoutryCode1] = useState<string>(
    countryCode ? countryCode : "1"
  );
  const [gradeList1, setGradeList1] = useState([]);
  const [grade1, setGrade1] = useState<any>("");
  const [step, setStep] = useState<string>("step1");
  const [otp, setotp] = useState("");
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);
  const [{ inputType }] = React.useState({
    numInputs: 4,
    separator: "-",
    minLength: 0,
    maxLength: 40,
    placeholder: "-",
    inputType: "text" as const,
  });
  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    setStep("step1");
    // setemail1("");
    setfirstname1("");
    setlastName1("");
    setmob1("");
    setGrade1("");
    handleClose();
  }
  useEffect(() => {
    dispatch(
      getCountry(
        (data: any) => {
          setCountryData1(data);
        },
        () => {}
      )
    );
    dispatch(
      gradeLevelList(
        (data: any) => {
          const filterGrade = data?.filter((item: any) => item?._id >= 7);
          setGradeList1(filterGrade);
        },
        () => {}
      )
    );
  }, []);

  const handleFirstInputText1 = (e: any) => {
    if (validateFullName(e)) setfirstname1(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleLastInputText1 = (e: any) => {
    if (validateFullName(e)) setlastName1(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };
  const setPhoneNumber1 = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob1(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const onChangeCoutryCode1 = (e: any) => {
    setcoutryCode1(e.target.value);
  };
  const handleOTPChange = (otp: string) => {
    setotp(otp.toString());
  };
  function OTPValidate() {
    return otp?.length < 6;
  }
  const handleSubmitDSAT = (e: any) => {
    e.preventDefault();

    const postData = {
      firstName: firstName1,
      lastName: lastName1,
      // email: email1,
      mobile: mob1,
      countryCode: "+1",
      countryId: "226",
      gradeId: grade1,
      coursetypeId: 15,
      sourceId: 5,
      statusId: 1,
      otpVerified: true,
      smsourceId: id,
    };
    dispatch(
      addDSATlandingpage(
        postData,
        (res: any) => {
          const payload = {
            mobile: mob1,
          };
          dispatch(
            sendOtp(
              payload,
              (data: any) => {
                if (data?.statusCode === service?.status_code?.success) {
                  if (data?.data?.isRegistered === true) {
                    dispatch(
                      updateMoreValues({
                        ACCESS_TOKEN: data.data.authToken,
                      })
                    );
                    dispatch(updateMoreValues({ email: data.data.email }));
                    dispatch(
                      updateMoreValues({
                        countryCode: data.data.countryCode,
                      })
                    );
                    dispatch(
                      updateMoreValues({ firstName: data.data.firstName })
                    );
                    dispatch(
                      updateMoreValues({ lastName: data.data.lastName })
                    );
                    dispatch(updateMoreValues({ mobileNo: data.data.mobile }));
                    dispatch(
                      updateMoreValues({
                        examTypeId: data.data.examtypeId?._id,
                      })
                    );
                    dispatch(
                      updateMoreValues({
                        examTypeName: data.data.examtypeId?.name,
                      })
                    );
                    dispatch(
                      updateMoreValues({
                        courseType: data.data.coursetypeId,
                      })
                    );
                    dispatch(
                      updateMoreValues({
                        parentName: data.data.parentName,
                      })
                    );
                    dispatch(
                      updateMoreValues({
                        parentNumber: data.data.parentNumber,
                      })
                    );
                    dispatch(
                      updateMoreValues({
                        parentEmail: data.data.parentEmail,
                      })
                    );
                    dispatch(
                      updateMoreValues({ altNumber: data.data.altNumber })
                    );
                    dispatch(updateMoreValues({ userId: data.data.userId }));
                    service.setAuthorizationToken(data.data.authToken);
                    dispatch(
                      updateMoreValues({ studentId: data.data.studentId })
                    );
                    dispatch(
                      updateMoreValues({
                        hasActivePaidPlan: data.data.hasActivePaidPlan,
                      })
                    );

                    navigate("/ThankYou", {
                      state: { pagename: "CollegeCounseling" },
                    });
                  }
                } else {
                  dispatch(showErrorSnackbar("wentWrong"));
                }
              },
              () => {}
            )
          );
        },
        (error: any) => {
          dispatch(showErrorSnackbar(error?.data?.msg));
        }
      )
    );
  };
  const getGrade = (val: any) => {
    setGrade1(val);
  };

  function validation() {
    return (
      email1 === "" ||
      !validateEmailAddress1(email1) ||
      (coutryCode1 !== "1" && mob?.length < 10) ||
      firstName1 === "" ||
      lastName1 === "" ||
      mob1 === "" ||
      grade1 === "" ||
      isDisabledBtn
    );
  }

  const onclickSendotp = () => {
    if (mob1 === "") {
      dispatch(showErrorSnackbar("Please enter all details"));
    } else {
      const formatPh = "+" + coutryCode1 + mob1;
      let verifier = new RecaptchaVerifier(auth, "recaptcha-container1", {
        size: "invisible",
      });
      setIsDisabledBtn(true);

      if (auth && formatPh && verifier) {
        signInWithPhoneNumber(auth, formatPh, verifier)
          .then(function (result: any) {
            if (result) {
              window.confirmationResult = result;
              dispatch(showSuccessSnackbar("OTP sent successfully!"));
              verifier.clear();
              const payload: any = {
                firstName: firstName1,
                lastName: lastName1,
                email: email1,
                mobile: mob1,
                countryCode: "+1",
                countryId: "226",
                gradeId: grade1,
                coursetypeId: 15,
                sourceId: 5,
                statusId: 1,
                otpVerified: false,
                smsourceId: id,
              };
              dispatch(
                addDSATlandingpage(
                  payload,
                  (data: any) => {
                    setIsDisabledBtn(false);
                    setStep("step2");
                  },
                  (error: any) => {
                    dispatch(showErrorSnackbar(error?.data?.msg));
                  }
                )
              );
            }
          })
          .catch(function (err: any) {
            setIsDisabledBtn(false);
            dispatch(showErrorSnackbar(err.name));
            verifier.clear();
            // window.location.reload()
          });
      }
    }
  };
  const onOTPVerify = () => {
    window.confirmationResult
      .confirm(otp)
      .then(async (res: any) => {
        const postData = {
          firstName: firstName1,
          lastName: lastName1,
          email: email1,
          mobile: mob1,
          countryCode: "+1",
          countryId: "226",
          gradeId: grade1,
          coursetypeId: 15,
          sourceId: 5,
          statusId: 1,
          otpVerified: true,
          smsourceId: id,
        };
        dispatch(
          addDSATlandingpage(
            postData,
            (res: any) => {
              const payload = {
                mobile: mob1,
              };
              dispatch(
                sendOtp(
                  payload,
                  (data: any) => {
                    if (data?.statusCode === service?.status_code?.success) {
                      if (data?.data?.isRegistered === true) {
                        dispatch(
                          updateMoreValues({
                            ACCESS_TOKEN: data.data.authToken,
                          })
                        );
                        dispatch(updateMoreValues({ email: data.data.email }));
                        dispatch(
                          updateMoreValues({
                            countryCode: data.data.countryCode,
                          })
                        );
                        dispatch(
                          updateMoreValues({ firstName: data.data.firstName })
                        );
                        dispatch(
                          updateMoreValues({ lastName: data.data.lastName })
                        );
                        dispatch(
                          updateMoreValues({ mobileNo: data.data.mobile })
                        );
                        dispatch(
                          updateMoreValues({
                            examTypeId: data.data.examtypeId?._id,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            examTypeName: data.data.examtypeId?.name,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            courseType: data.data.coursetypeId,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            parentName: data.data.parentName,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            parentNumber: data.data.parentNumber,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            parentEmail: data.data.parentEmail,
                          })
                        );
                        dispatch(
                          updateMoreValues({ altNumber: data.data.altNumber })
                        );
                        dispatch(
                          updateMoreValues({ userId: data.data.userId })
                        );
                        service.setAuthorizationToken(data.data.authToken);
                        dispatch(
                          updateMoreValues({ studentId: data.data.studentId })
                        );
                        dispatch(
                          updateMoreValues({
                            hasActivePaidPlan: data.data.hasActivePaidPlan,
                          })
                        );

                        navigate("/ThankYou", {
                          state: { pagename: "CollegeCounseling" },
                        });
                      }
                    } else {
                      dispatch(showErrorSnackbar("wentWrong"));
                    }
                  },
                  () => {}
                )
              );
            },
            (error: any) => {
              dispatch(showErrorSnackbar(error?.data?.msg));
            }
          )
        );
      })
      .catch((err: any) => {});
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      onClose={closePopup}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modeldiv p-0 position-relative container">
          <div className="row mx-0 bgBlue">
            <div className="d-none d-md-block col-md-5">
              <div className=" d-flex justify-content-center align-items-center h-100">
                <div>
                  <div className="font28 colorWhite fw-bold text-center">
                    Start your journey to your
                  </div>
                  <div className="font28 colorOrange fw-bold text-center">
                    Dream University today!
                  </div>
                  <div className="text-center mt-4">
                    <img
                      src={localImages?.booksessionimg}
                      alt="Learn"
                      className="w-100"
                      style={{ maxWidth: "300px" }}
                    />
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div className="col-md-7 bgwhite rounded overflow-hidden p-3">
              <div className="bookfreeSession">
                <div className="col mb-2 d-flex justify-content-end ">
                  <ClearOutlinedIcon
                    className="colorblue cursor font28"
                    onClick={closePopup}
                  />
                </div>
                <div className="mt-4">
                  <div className="font24 colorgray fw-bold px-3 mb-4">
                    Book Your Trial Session Now!
                  </div>
                  <div className="mt-4">
                    {/* <form className="w-100" onSubmit={handleSubmitDSAT}> */}
                    {step === "step1" ? (
                      <div className="row mx-0 d-flex">
                        <div className="my-2 col-md-6">
                          <label className="mb-2 text-dark">
                            First Name <span className="  ">*</span>
                          </label>
                          <div className="d-flex ">
                            <InputField
                              placeholder="Enter First Name"
                              value={firstName1}
                              setValue={handleFirstInputText1}
                            />
                          </div>
                        </div>
                        <div className="my-2 col-md-6">
                          <label className="mb-2 text-dark">
                            Last Name <span className="">*</span>
                          </label>
                          <div className="d-flex ">
                            <InputField
                              placeholder="Enter Last Name"
                              value={lastName1}
                              setValue={handleLastInputText1}
                            />
                          </div>
                        </div>
                        <div className="my-2  col-md-6">
                          <label className="mb-2 text-dark">
                            Phone Number <span>*</span>
                          </label>
                          <div className="d-flex position-relative">
                            <select
                              className="form-select cursor coutryCode  rounded-0 rounded-start"
                              aria-label="Default select example"
                              defaultValue={1}
                              value={coutryCode1}
                              onChange={onChangeCoutryCode1}
                              disabled
                            >
                              {countryData1?.map((item: any, index) => {
                                return (
                                  <option value={item.phonecode}>
                                    {item?.isoCode} +{item?.phonecode}
                                  </option>
                                );
                              })}
                            </select>
                            <PhoneInputField
                              placeholder="Enter Mobile Number"
                              value={mob1}
                              maxLength={"10"}
                              setValue={setPhoneNumber1}
                            />
                          </div>
                        </div>
                        <div className="my-2 col-md-6 ">
                          <label className="mb-2 text-dark">
                            Email <span>*</span>
                          </label>
                          <div className="d-flex">
                            <input
                              className="form-control"
                              type="email"
                              value={email1}
                              placeholder="Enter Email Address"
                              onChange={(e) => setemail1(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="my-2 col-md-6 ">
                          <label className="mb-2 text-dark">
                            Student Grade <span>*</span>
                          </label>
                          <div className="  ">
                            <Dropdown
                              placeholder="Select Grade"
                              options={gradeList1}
                              value={grade1}
                              setInputText={(value: any) => getGrade(value)}
                            />
                          </div>
                        </div>

                        <div id="recaptcha-container1"></div>
                        <div className="d-flex justify-content-center mb-5 mt-4 text-center">
                          <button
                            onClick={onclickSendotp}
                            className={`bookSessionButton  col-md-7  rounded fw-bold ${
                              validation() ? "" : "active"
                            }`}
                            disabled={validation()}
                            type="button"
                          >
                            Book your counseling session{" "}
                          </button>
                        </div>
                      </div>
                    ) : step === "step2" ? (
                      <div className="row mx-0">
                        <div className="">
                          <h3 className="boldfont text-center mb-2 bookSessionOrange mt-5">
                            Please enter OTP to schedule a live class with your
                            teacher
                          </h3>
                          <div className="font16 colorgray text-center my-1">
                            Enter a 6 digit OTP sent to{" "}
                            <span className="bookSessionOrange fw-bold">
                              +{coutryCode1} {mob1}
                            </span>
                          </div>

                          <div className="">
                            <div className="otpBox cursor mt-2">
                              <OTPInput
                                inputStyle="inputStyle"
                                numInputs={6}
                                onChange={handleOTPChange}
                                renderSeparator={
                                  <span className="mx-2 mx-md-3"></span>
                                }
                                value={otp}
                                inputType={inputType}
                                renderInput={(props) => <input {...props} />}
                                shouldAutoFocus
                              />
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mb-5 mt-4 text-center">
                          <button
                            type="button"
                            onClick={onOTPVerify}
                            className={`bookSessionButton col-md-7 rounded fw-bold ${
                              OTPValidate() ? "" : "active"
                            }`}
                            disabled={OTPValidate()}
                          >
                            Verify OTP{" "}
                          </button>
                        </div>
                      </div>
                    ) : step === "step3" ? (
                      <>
                        <div className="row d-flex justify-content-between">
                          <div className="my-2 col-md-6">
                            <label className="mb-2 text-dark">
                              Parents First Name <span className="  ">*</span>
                            </label>
                            <div className="d-flex ">
                              <InputField
                                placeholder="Enter Parents Name"
                                value={firstName1}
                                setValue={handleFirstInputText1}
                              />
                            </div>
                          </div>
                          <div className="my-2 col-md-6">
                            <label className="mb-2 text-dark">
                              Parents Last Name <span className="">*</span>
                            </label>
                            <div className="d-flex ">
                              <InputField
                                placeholder="Enter Parents Name"
                                value={lastName1}
                                setValue={handleLastInputText1}
                              />
                            </div>
                          </div>
                          <div className="my-2 col-md-6 ">
                            <label className="mb-2 text-dark">
                              Student Grade <span>*</span>
                            </label>
                            <div className="  ">
                              <Dropdown
                                placeholder="Select Grade"
                                options={gradeList1}
                                value={grade1}
                                setInputText={(value: any) => getGrade(value)}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mb-5 mt-4 text-center">
                            <button
                              type="button"
                              onClick={handleSubmitDSAT}
                              className={`bookSessionButton col-md-7 rounded fw-bold ${
                                OTPValidate() ? "" : "active"
                              }`}
                              disabled={OTPValidate()}
                            >
                              Submit{" "}
                            </button>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
