import React from 'react'
import "../../Assets/Css/SEOTemplate/collegeLogos.css"
function CollegeLogos({Item}) {
    const CollegeLogosDatas=JSON.parse(Item.content)    
  return (
    <div className='commonPad'>
    <div className='align-items-center  overflow-hidden'>
   <div className={`universitySeo ${CollegeLogosDatas?.length>10?'scrollAnimation':''}`}>
        {CollegeLogosDatas?.map((item:any, index:number) =>  
            
            <div className='universitycard'>
              <img
                src={item.ColgLogoImg}
                className="img-fluid"
                alt="Universities"
              />
            </div> 
             
         )} 
  </div>
      </div>

      
    </div>
  )
}

export default CollegeLogos
