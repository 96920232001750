// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Images/LandingPage/cardBG.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradeCard{
    
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
}
.cardheading {
    font-size: 1.2em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
.gradeDiv:nth-child(odd) .gradeCard {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/SEOTemplate/gradeCard.css"],"names":[],"mappings":"AAAA;;IAEI,sBAAsB;IACtB,kBAAkB;IAClB,4BAA4B;AAChC;AACA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,oBAAoB;IACpB,qBAAqB;IACrB,4BAA4B;EAC9B;AACF;EACE,yDAA4D;EAC5D,sBAAsB;EACtB,kBAAkB;EAClB,4BAA4B;AAC9B","sourcesContent":[".gradeCard{\n    \n    background-size: cover;\n    position: relative;\n    background-repeat: no-repeat;\n}\n.cardheading {\n    font-size: 1.2em;\n    overflow: hidden;\n    display: -webkit-box;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n  }\n.gradeDiv:nth-child(odd) .gradeCard {\n  background-image: url(\"../../Images/LandingPage/cardBG.png\");\n  background-size: cover;\n  position: relative;\n  background-repeat: no-repeat;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
