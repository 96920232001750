import React, { memo } from 'react'

interface props {
  faqData:{title: string; desc1: string ,desc2: string,desc3: string}[],
  id:string
}
function ProgramFaq({ faqData,id }:props) {
  return (
    <div className="accordion accordion-flush w-100" id={id}>
    {faqData?.map((faqdata, faqindex) => {
      if (faqindex <= 9) {
      return (
        <div className="accordion-item" key={faqindex}>
          <h2 className="accordion-header" id={"heading" + faqindex}>
            <button
              className={`accordion-button boldfont  ${faqindex === 0 ? "" : "collapsed"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={"#collapse" + faqindex}
              aria-expanded={faqindex === 0 ? "true" : "false"}
              aria-controls={"collapse" + faqindex}
            >
              {faqdata?.title}
            </button>
          </h2>
          <div
            id={"collapse" + faqindex}
            className={`accordion-collapse collapse ${
              faqindex === 0 ? "show" : ""
            }`}
            aria-labelledby={"heading" + faqindex}
            data-bs-parent={"#"+id}
          >
            <div className="accordion-body">  <ul className="mt-4" style={{ listStyle: "disc" }}>
                <li className="text-dark font16 mt-3">{faqdata?.desc1}</li>
                <li className="text-dark font16 mt-3">{faqdata?.desc2}</li>
                <li className="text-dark font16 mt-3">{faqdata?.desc3}</li>
              </ul></div>
          </div>
        </div>
      );}
    })}
  </div>
  )
}
export default memo(ProgramFaq);
