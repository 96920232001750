import React from "react";
import "../../Assets/Css/SEOTemplate/studentReview.css";
import { Rating } from "@mui/material";
import StarRateIcon from "@mui/icons-material/StarRate";
import parser from "html-react-parser";
import { Link } from "react-router-dom";
function ReviewAndRating({ Item }) {
  const RatingPanleDatas = JSON.parse(Item.content);
  console.log("RatingPanleDatas",RatingPanleDatas)
  console.log("RatingPanleDatasratingArr",RatingPanleDatas?.ratingArr)
  return (
    <div>
      <div className="commonPad bgLightGrey">
          <div className="width800 mx-auto my-5">
            <h1 className="headingFont text-center">
             {RatingPanleDatas.MainHeading}
            </h1>
           <div className="bannersubtext mb-3 text-center">
            {RatingPanleDatas.MainSubHeading}
            </div>
          </div>

      <div
        id="carouselStudent"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {RatingPanleDatas?.ratingArr?.map((item: any, index: number) => (
            <div className={`carousel-item ${index === 0 ? "active" : ""}`}>
              <div
                className=" container text-center w-100 p-4"
                style={{ backgroundColor: "#f6f9fd" }}
              >
                <div className=" "> 
                  {item?.Rating ? (
                    <Rating
                      className="ratingStar"
                      value={item?.Rating}
                      disabled={true}
                    />
                  ) : null}
                  <div className="text-center fw-bold fs-3 mx-2">
                    {item.Heading}
                  </div>
                  <div className="Container-subHeading text-center">
                    {item.SubHeading}
                  </div>
                  <div
                    className="desc-info py-3 fs-6"
                    style={{ maxWidth: "80%" }}
                  >
                    {parser(item?.Description)}
                  </div>
                  <div className="d-flex justify-content-center">
                    <div>
                      <Link
                        className="starPilotBox rounded-pill d-flex align-items-center justify-content-center mb-2 text-decoration-none"
                        to={item?.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <StarRateIcon
                          style={{ color: "#04da8d", fontSize: "18px" }}
                          className="me-1"
                        />
                        Trustpilot
                      </Link>
                      <div className="fs-6 my-2">{item?.Name}</div>
                      <div className="fs-6   ">{item?.Date}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex ">
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target={"#" + "carouselStudent"}
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target={"#" + "carouselStudent"}
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <div className="carousel-indicators ">
          {RatingPanleDatas?.ratingArr?.map((item: any, index: number) => (
            <button
              key={index}
              type="button"
              data-bs-target={"#" + "carouselStudent"}
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : undefined}
              aria-label={"Slide " + (index + 1)}
            ></button>
          ))}
        </div>
      </div>
      </div>
    </div>
  );
}

export default ReviewAndRating;
