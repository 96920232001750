// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bgpink{
    background: #FAE9E2;
    color: #101010;
  }
  .topicCard {
    background: #F15D22; 
    transition: background 0.3s ease-in-out;
    border-radius: 8px; 
  }
  
  .topicSectionData:hover {
    background-image: linear-gradient(100deg, #ffc5b3 -61%, #ffff 100%);
    border-left: 10px solid #F15D22;
    color: #000;
    cursor: pointer; 
    border-radius: 5px;
  }
  
  .topicSectionData {
    padding: 15px 20px;
    color: #fff;
  }
  
  .topicSectionData.active {
    /* padding: 15px 20px; */
    background: #ffff !important;
    border-left: 10px solid #F15D22 !important;
    color: #000 !important;
    border-radius: 5px !important;
  }
  .topicDiv{
    max-height: calc(100vh - 473px);
    overflow: auto;
  }`, "",{"version":3,"sources":["webpack://./src/Assets/Css/SEOTemplate/topicSection.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc;EAChB;EACA;IACE,mBAAmB;IACnB,uCAAuC;IACvC,kBAAkB;EACpB;;EAEA;IACE,mEAAmE;IACnE,+BAA+B;IAC/B,WAAW;IACX,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,wBAAwB;IACxB,4BAA4B;IAC5B,0CAA0C;IAC1C,sBAAsB;IACtB,6BAA6B;EAC/B;EACA;IACE,+BAA+B;IAC/B,cAAc;EAChB","sourcesContent":[".bgpink{\n    background: #FAE9E2;\n    color: #101010;\n  }\n  .topicCard {\n    background: #F15D22; \n    transition: background 0.3s ease-in-out;\n    border-radius: 8px; \n  }\n  \n  .topicSectionData:hover {\n    background-image: linear-gradient(100deg, #ffc5b3 -61%, #ffff 100%);\n    border-left: 10px solid #F15D22;\n    color: #000;\n    cursor: pointer; \n    border-radius: 5px;\n  }\n  \n  .topicSectionData {\n    padding: 15px 20px;\n    color: #fff;\n  }\n  \n  .topicSectionData.active {\n    /* padding: 15px 20px; */\n    background: #ffff !important;\n    border-left: 10px solid #F15D22 !important;\n    color: #000 !important;\n    border-radius: 5px !important;\n  }\n  .topicDiv{\n    max-height: calc(100vh - 473px);\n    overflow: auto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
