import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import '../Assets/Css/home.css'; 
import dataConstant from '../Constant/dataConstant';
import localImages from '../Constant/localImagesNew';
import CountUp from "react-countup";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ChangingProgressProvider from './ChangingProgressProvider';

function Stats(props:any) {
  const [visibleStats, setVisibleStats] = useState<{ [key: number]: boolean }>({});

  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = parseInt(entry.target.getAttribute('data-index') || '0', 10);
          setVisibleStats((prev) => ({ ...prev, [index]: true }));
          observer.current?.unobserve(entry.target);
        }
      });
    }, { threshold: 0.5 });

    const elements = document.querySelectorAll('.statsBox');
    elements.forEach((el) => observer.current?.observe(el));

    return () => {
      observer.current?.disconnect();
    };
  }, []);

  return (
    <div className='commonPad bgBlue learnSolDiv'>
      <img src={localImages?.learnSolleft} className='learnSolleft' alt='learnSolleft' />
      <img src={localImages?.learnSolright} className='learnSolright' alt='learnSolright' />
      <div className='width800 mx-auto mt-5 mb-5'>
        <h1 className='headingFont text-center'>Why should you consider us?</h1>
        <div className='text-center font20'>Our stats speak for itself</div>
      </div>
      <div className='mt-5 w-100 py-5 d-flex justify-content-evenly flex-wrap'>
        {dataConstant?.stats?.map((item, index) => {
          const animationOption = {
            loop: true,
            autoplay: true,
            animationData: item?.animationPath,
            renderer: "svg",
          };
          return (
            <div 
              className="statsBox text-start my-2 wow fadeInUp" 
              data-wow-duration="1s" 
              data-wow-delay={`${index * 0.5}s`}
              data-index={index}
              key={index}
            >
              <div className='stateBox h-100 py-4'>
                {visibleStats[index] && (
                  <div className='statNo'>
                    <CountUp end={parseInt(item?.count)} duration={2} delay={index * 0.25}/>{item?.AdditionText}
                  </div>
                )}
                
                <div className='statHeading'>{item?.heading}</div>
                {visibleStats[index] && item?.percentage?<div className="progressBarWrapper mt-4 responsive-lottie">
                  <ChangingProgressProvider values={[0, 90]}>
                    {percentage => (
                      <CircularProgressbar value={percentage}  styles={buildStyles({
                        textSize: '16px',
                        pathColor: `rgba(255, 255, 255, ${item.percentage / 100})`,
                        textColor: '#f88',
                        trailColor: 'rgb(88, 120, 179)',
                        backgroundColor: '#3e98c7',
                      })}
                      strokeWidth={15}/>
                    )}
                  </ChangingProgressProvider>
                    </div>:
                    <div className='mt-5 responsive-lottie'>
                    <Lottie
                      options={animationOption}
                    />
                  </div>}
                            
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Stats;
