// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*======================
    404 page
=======================*/


.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Arvo', serif;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {

    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
}

.text404 {
    font-size: 80px;
}
.four_zero_four_bg h3 {
    font-size: 80px;
}
.link_404 {
    padding: 10px 20px;
    margin: 5px 0;
    display: inline-block;
    background: var(--theme2);
    color: var(--white);
    /* font-size: 1em;
    border: 0;
    padding: 10px; */
}
.contant_box_404 {
    margin-top: -50px;
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/pageNotFound.css"],"names":[],"mappings":"AAAA;;wBAEwB;;;AAGxB;IACI,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,WAAW;AACf;;AAEA;;IAEI,+FAA+F;IAC/F,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,qBAAqB;IACrB,yBAAyB;IACzB,mBAAmB;IACnB;;oBAEgB;AACpB;AACA;IACI,iBAAiB;AACrB","sourcesContent":["/*======================\n    404 page\n=======================*/\n\n\n.page_404 {\n    padding: 40px 0;\n    background: #fff;\n    font-family: 'Arvo', serif;\n}\n\n.page_404 img {\n    width: 100%;\n}\n\n.four_zero_four_bg {\n\n    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);\n    height: 400px;\n    background-position: center;\n}\n\n.text404 {\n    font-size: 80px;\n}\n.four_zero_four_bg h3 {\n    font-size: 80px;\n}\n.link_404 {\n    padding: 10px 20px;\n    margin: 5px 0;\n    display: inline-block;\n    background: var(--theme2);\n    color: var(--white);\n    /* font-size: 1em;\n    border: 0;\n    padding: 10px; */\n}\n.contant_box_404 {\n    margin-top: -50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
