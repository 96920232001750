import React from "react";
interface Courseobj {
  id: number | string;
  name: string;
}
interface props {
  placeholder?: string;
  value: string;
  setInputText: Function;
  defaultValue?: string;
  disabled?:boolean;
  options: { _id: number | string; name: string; Name: string;
   }[];
}
export default function Dropdown({
  placeholder,
  value,
  setInputText,
  defaultValue,
  options,
  disabled
}: props) {
  React.useEffect(() => {
    const newArray = options?.filter(function (el: { _id: any }) {
      return el._id === defaultValue;
    });
    newArray?.map((item: { name: any,_id:any }) => {
      setInputText(item?._id);
    });
  }, [defaultValue !== "" && options?.length > 0]);
  const handleChange = (e: React.SyntheticEvent<EventTarget>) => {
    setInputText((e.target as HTMLInputElement).value);
  };
  console.log('disabled', disabled);
  return (
    <select
      className="form-select cursor form-select-sm"
      aria-label="Default select example"
      //  defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      {...(disabled ? { disabled: true } : {})}
    >
      {placeholder?<option   value="">{placeholder}</option>:null}
      {options?.map((item, index) => {
        return <option value={item._id}>{item.name ? item.name : item.Name}</option>;
      })}
    </select>
  );
}
