// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#imageTamplate{
    /* margin-top: 76px; */
}
.btnjoin{
    background: var(--theme2);
    color: var(--white);
    font-size: 1em;
    border: 0;
    padding: 10px;
    max-width: 50%;
    border-radius: 5px;
    
}
.video-main-sec{
    height: 100%;
    width: 100%;
}
/* .button-line{
    position: absolute;
    bottom:  -4%;
    left: 0;
    right: 0;
}
.bg-img-buttom-stp{
    height: 30px;
    background: var(--theme2);
    width: 100%;
}
.video-orange-stp{
    height: 55px;
    background-color: #ff5f2f;
    position: absolute;
    left: -10%;
    right: 45%;
    bottom: 0%;
}
.video-orange-right-stp{
    height: 55px;
    background-color: #ff5f2f;
    position: absolute;
    right: -10%;
    left: 45%;
    bottom: 0%;
} */
`, "",{"version":3,"sources":["webpack://./src/Assets/Css/SEOTemplate/imageTamplate.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,SAAS;IACT,aAAa;IACb,cAAc;IACd,kBAAkB;;AAEtB;AACA;IACI,YAAY;IACZ,WAAW;AACf;AACA;;;;;;;;;;;;;;;;;;;;;;;;;;GA0BG","sourcesContent":["#imageTamplate{\n    /* margin-top: 76px; */\n}\n.btnjoin{\n    background: var(--theme2);\n    color: var(--white);\n    font-size: 1em;\n    border: 0;\n    padding: 10px;\n    max-width: 50%;\n    border-radius: 5px;\n    \n}\n.video-main-sec{\n    height: 100%;\n    width: 100%;\n}\n/* .button-line{\n    position: absolute;\n    bottom:  -4%;\n    left: 0;\n    right: 0;\n}\n.bg-img-buttom-stp{\n    height: 30px;\n    background: var(--theme2);\n    width: 100%;\n}\n.video-orange-stp{\n    height: 55px;\n    background-color: #ff5f2f;\n    position: absolute;\n    left: -10%;\n    right: 45%;\n    bottom: 0%;\n}\n.video-orange-right-stp{\n    height: 55px;\n    background-color: #ff5f2f;\n    position: absolute;\n    right: -10%;\n    left: 45%;\n    bottom: 0%;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
