import React, { useState } from "react";
import "../../Assets/Css/SEOTemplate/imageTamplate.css";
function Country({Item}) {
  const imgDatass=JSON.parse(Item.content)
  function goToBookSession(): void {
    // Navigate("/BookSession",{ state: { mobNo: "",coutryCodeNo:"",isOTPVerify:ACCESS_TOKEN?true:false } });
  }
  console.log("imgDatass",imgDatass)
  return (
    <div id="imageTamplate">
      <div className=" homeBannerContainer" id="homebanner">
        <div className="row mx-0 p-0">
          {imgDatass?.Direction ===1 ? (
            <>
              <div className="col-12 col-md-6 col-lg-6 col-xl-6 homeBannerLeftSide">
                <div className=" d-flex flex-column justify-content-center h-100 homeBannerLeftSide">
                  <h1 className="homeBannerHeading">
                  {imgDatass?.homeHeading}
                  </h1>
                 
                  <div className="bannersubtext mt-3">
                    {imgDatass?.homeSubHeading}
                  </div>
                  <div className="mt-5">
                    <button
                      className="px-4 font16  align-items-start btnjoin py-2"
                      onClick={goToBookSession}
                    >
                      Join Lurnigo Today
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-6 px-0">
                <div className=" position-relative pe-md-0">
                  {imgDatass?.doctype !=="img" ? (
                    <div className="video-main-sec h-100">
                    <video muted autoPlay loop className="videotag m-auto">
                      <source
                        src={imgDatass?.HomeVideo}
                        type="video/mp4"
                        style={{width:"100%",height:"100%"}}
                      />
                    </video>
                    </div>
                  ) : (
                    <div>
                      <img
                        src={imgDatass?.Homeimg}
                        alt={imgDatass?.AltText}
                        style={{ width: "100%" }}
                        className="bannerImg"
                      />
                    </div>
                  )}
                  <div className="row align-items-baseline bottomStripleft">
                    <div className="col-6 videoOrangeStrip"></div>
                    <div className="col-6 videoBlueStrip"></div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-12 col-md-6 col-lg-6 col-xl-6 px-0">
                <div className=" position-relative pe-md-0">
                  {imgDatass.doctype !=="img"  ? (
                    <video muted autoPlay loop className="videotag m-auto">
                      <source
                        src={require("../../Assets/Video/homeBannerVideo.mp4")}
                        type="video/mp4"
                      />
                    </video>
                  ) : (
                    <div>
                      <img
                        src={imgDatass.Homeimg}
                        alt="img"
                        style={{ width: "100%" }}
                        className="bannerImg"
                      />
                    </div>
                  )}

                  <div className="row align-items-baseline bottomStripleft">
                    <div className="col-6 videoOrangeStrip"></div>
                    <div className="col-6 videoBlueStrip"></div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-6 pb-3 ">
                <div className=" d-flex flex-column justify-content-center h-100 homeBannerLeftSide">
                  <h1 className="homeBannerHeading">
                    {imgDatass.homeHeading}
                  </h1>
                  <div className="py-3 fs-5">
                  {imgDatass.homeSubHeading}
                  </div>
                  <button
                    className="px-4 font16  align-items-start btnjoin py-2"
                    onClick={goToBookSession}
                  >
                    Join Lurnigo Today
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Country;
