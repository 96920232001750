// Validation functions start
 
export const containsSpecialChars = (str:any) => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
};

export function containsNumber(str:any) {
  return /\d/.test(str);
}
export function hasWhiteSpace(str:any) {
  return /\s/g.test(str);
}

export function startsWithSpace(str:any){
  return /^\s/.test(str)
}

export function startsWithNumber(str:any){
  return /^\d/.test(str)
}


export function validateFullName(str:any) {
  if (
    typeof str !== 'string' ||
    /[0-9]+/g.test(str) ||
    containsSpecialChars(str) ||
    startsWithSpace(str)
  ) {
    return false;
  }
  return true;
}

export const validateIsLettersAndDotOnly = (value:any) => {
  return /^[a-zA-Z\s,.]*$/.test(value);
  
  
};

export const validateIsNumberOnly = (value:any) => {
  return /\D/.test(value);
  
};

 
export const validateEmailAddress = (value:any) => {
  return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/.test(value);
};
export const validateEmailAddress1 = (value:any) => {
  return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(value);
};

export function validateCouponCodeName(str:string) {
  if (
    startsWithNumber(str) ||
    startsWithSpace(str) ||
    hasWhiteSpace(str) ||
    containsSpecialChars(str)
  ) {
    return false;
  }
  return true;
}

export function validatePanCard(value:any) {
  return /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value);
}

export const length_One = 1;
export const length_Two = 2;
export const length_Three = 3;
export const length_Four = 4;
export const length_Five = 5;
export const length_Six = 7;
export const length_Eight = 8;
export const length_Ten = 10;
export const length_Twenty = 20;
export const length_Thirty = 31;
export const length_Fifty = 51;
export const length_Hundred = 101;
export const length_OneHundredFifty = 151;
export const length_TwoHundred = 201;
export const length_ThreeHundred = 300;
export const length_FiveHundred = 500;
export const length_OneThousandFiveHundred = 1500;
export const length_FiveThousand = 5000;

// Image size in kb
export const image_size_200 = 204800;
export const image_size_150 = 153600;
export const image_size_5MB = 5120000;
export const image_size_1MB = 1048576;
export const pdf_size_1MB = 10240000;
export const pdf_size_5MB = 5120000;
export const pdf_size_50MB = 52428800;
export const pdf_size_100MB = 104857600;
export const book_price = 10001;
export const video_size_25MB = 25600000;

// Validation functions ends
