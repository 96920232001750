import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "../hooks";
import { GetCountryCodesList } from "../Redux/Actions/AuthAction";
import Stats from "../Module/stats";

import MetaDataFile from "../Component/metaDataFile";
import HeaderHome from "../Component/headerHome";
import Footer from "../Component/footer";
import CollegeLogos from "../Component/SEOTemplate/CollegeLogos";
import ImageTemplate from "../Component/SEOTemplate/ImageTemplate";
import CourseType from "../Component/SEOTemplate/CourseType";
import CourseFeatures from "../Component/SEOTemplate/CourseFeatures";
import LurnigoProcess from "../Component/SEOTemplate/LurnigoProcess";
import NewFaq from "../Component/SEOTemplate/NewFaq";
import RatingPanel from "../Component/SEOTemplate/ratingPanel";
import ReviewAndRating from "../Component/SEOTemplate/ReviewAndRating";
import TopicSection from "../Component/SEOTemplate/topicSection";
import SeoSubscriptionPlan from "../Component/SEOTemplate/seoSubscriptionPlan";


  function Country() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate(); 
  const { seoSlug } = useParams();
  const statRef: any = useRef(null); 
  const [countryData, setCountryData] = useState<any>(null); 
  const [loading, setLoading] = useState<boolean>(true);
  const [seletectedFaqData, setSeletectedFaqData] = useState<any>(null);
  console.log("seoSlug",seoSlug)
  useEffect(() => {
    const PostData = { country: seoSlug };
    setLoading(true); 
    dispatch(
      GetCountryCodesList(
        PostData,
        (res: any) => {
            if(res?.data?.length>0){
              setCountryData(res?.data[0]);
              if(res?.data[0]?.faqData?.length>0){
                setSeletectedFaqData(res?.data[0]?.faqData[0])
              }
          }
          setLoading(false); 
        },
        () => {
          setLoading(false); 
        }
      )
    );
  }, [seoSlug, dispatch]);

  
  if (loading) return 
  <div>Loading...</div>;
   if (!countryData) {
    navigate("/PageNotFound")
   }
  console.log("countryData",countryData);
//   const shortlist=countryData[0]?.contentOrder.sort((a:number,b:number)=>a-b)
//   console.log('shortlist', shortlist)
  
console.log('seletectedFaqData', seletectedFaqData);
const handelClick = (btn: any) => {
  setSeletectedFaqData(btn);
};

const contactUS = (val: string) => {
  const element = document.getElementById(val);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }
};

  return (
    <div>
        <MetaDataFile
          title={countryData?.meta_title}
          description={countryData?.meta_description}
          noScript={
            "https://www.facebook.com/tr?id=1750615618629260&ev=PageView&noscript=1"
          }
        />
        <HeaderHome isHidden={false} />
        {countryData?.content?.sort((a:any,b:any)=>a.contentType-b.contentType)?.map((item: any) =>
        item.contentType === 7 ? (
          <CollegeLogos Item={item} key={item.id} />
        ) : item.contentType === 5 ? (
          <ImageTemplate Item={item} key={item.id} />
        ) : item.contentType === 8 ? (
          <CourseType Item={item} key={item.id} />
        ) : item.contentType === 9 ? (
          <CourseFeatures Item={item} key={item.id} />
        ) : item.contentType === 11 ? (
          <div ref={statRef} key={item.id}>
            <Stats Item={item} />
          </div>
        ) : item.contentType === 10 ? (
          <LurnigoProcess Item={item} key={item.id} />
        ) : item.contentType === 6 ? (
            <RatingPanel  Item={item} key={item.id} />
        ) : item.contentType === 12 ? (
            <ReviewAndRating  Item={item} key={item.id} />
        ) : null
      )}
      
       <div className="commonPad ">
          <div className="row mx-0 ">
            <div className="col-md-3">
              <h1 className="headingFont mb-4">FAQs</h1>
              <div className="mb-4">
                <button
                  className="contactUSbtn px-5"
                  onClick={() => contactUS("Footer")}
                >
                  Contact Us
                </button>
              </div>
            </div>
            <div className="col col-sm-6 col-md-9 shadow-sm p-3 ">
                <div className="d-flex my-2 btn-group col-md-4" role="group" aria-label="Basic outlined example">
                  {countryData?.faqData?.map((btn: any, btnIndex: number) => (
                    <button
                    className={`btn btnfaqs ${seletectedFaqData?.type === btn.type  ? "active" : ""}`}
                      key={btnIndex}
                      onClick={() => handelClick(btn)}
                      style={{ cursor: "pointer",  }}
                    >
                      {btn.type}
                    </button>
                  ))}
              </div>
              <NewFaq id={"HomeFAQ"} Item={seletectedFaqData}/>
            </div>
          </div>
        </div>
      <Footer id="Footer" />
    </div>
  );
}

export default Country;
