// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .accordion-button:not(.collapsed)::after {
    background-image: url("../../Images/icons/nagative.png") !important;
}
.accordion-button::after {
    background-image: url("../../Images/icons/plus.png") !important;
} */
/* .faq-btns{
    background: #F1F9FF !important;
} */
.academic .accordion-button {
    color: var(--bookSessionOrange) !important;

}

.academic .accordion-button:not(.collapsed) {
    background: var(--bookSessionOrange) !important;
    color: #fff !important;
}

.academic .accordion-button:focus {
    box-shadow: none !important;
}

.academic .textContainer {
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.academic .accordion-item {
    border: 2px solid var(--bookSessionOrange) !important;

}

.academic .accordion-item:hover .accordion-button {
    background: var(--bookSessionOrange) !important;
    color: #fff !important;
}

.academic .accordion-item:hover .accordion-button.collapsed::after {
    color: #fff !important;
}
.centerImgAccordion{
    width: 400px;
    /* height: 400px;
    aspect-ratio: 1/1; */
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/SEOTemplate/NewFaq.css"],"names":[],"mappings":"AAAA;;;;;GAKG;AACH;;GAEG;AACH;IACI,0CAA0C;;AAE9C;;AAEA;IACI,+CAA+C;IAC/C,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,qDAAqD;;AAEzD;;AAEA;IACI,+CAA+C;IAC/C,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B;AACA;IACI,YAAY;IACZ;wBACoB;IACpB,iBAAiB;AACrB","sourcesContent":["/* .accordion-button:not(.collapsed)::after {\n    background-image: url(\"../../Images/icons/nagative.png\") !important;\n}\n.accordion-button::after {\n    background-image: url(\"../../Images/icons/plus.png\") !important;\n} */\n/* .faq-btns{\n    background: #F1F9FF !important;\n} */\n.academic .accordion-button {\n    color: var(--bookSessionOrange) !important;\n\n}\n\n.academic .accordion-button:not(.collapsed) {\n    background: var(--bookSessionOrange) !important;\n    color: #fff !important;\n}\n\n.academic .accordion-button:focus {\n    box-shadow: none !important;\n}\n\n.academic .textContainer {\n    width: 100%;\n    max-width: 1000px;\n    margin: auto;\n}\n\n.academic .accordion-item {\n    border: 2px solid var(--bookSessionOrange) !important;\n\n}\n\n.academic .accordion-item:hover .accordion-button {\n    background: var(--bookSessionOrange) !important;\n    color: #fff !important;\n}\n\n.academic .accordion-item:hover .accordion-button.collapsed::after {\n    color: #fff !important;\n}\n.centerImgAccordion{\n    width: 400px;\n    /* height: 400px;\n    aspect-ratio: 1/1; */\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
