import React, { useState } from "react";
import localImages from "../../Constant/localImagesNew";
import parser from "html-react-parser";
function CourseType({ Item }) {
  const CourseTypeDatas = JSON.parse(Item.content);
  const [tabValue, setTabValue] = useState<number>(0);
  return (
    <div className="commonPad bgBlue learnSolDiv">
      <img
        src={localImages?.learnSolleft}
        className="learnSolleft"
        alt="learnSolleft"
      />
      <img
        src={localImages?.learnSolright}
        className="learnSolright"
        alt="learnSolright"
      />
      <div className="learnSolHeadDiv">
        <h1>{CourseTypeDatas.Heading}</h1>
        <div className="mb-3 font16">
          {parser(CourseTypeDatas?.Description)}
        </div>
      </div>
      <div className="learnSolTab">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            {CourseTypeDatas.courseArr.map((item: any, i: number) => (
              <button
                key={i}
                // className={`nav-link ${tabValue === i ? "active" : ""}`}
                className={`nav-link ${tabValue === i ? 'active' : ''} ${i === 0 ? 'border-radius-start' : null} ${i === CourseTypeDatas.courseArr.length - 1 ? 'border-radius-end' : ''}`}
                id={`${item.Title}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#${item.Desc}-tab-pane`}
                type="button"
                role="tab"
                aria-controls={`${item.Desc}-tab-pane`}
                aria-selected={tabValue === i}
                onClick={() => setTabValue(i)}
              >
                {item.CourseTypeName}
              </button>
            ))}
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          {CourseTypeDatas.courseArr.map((item: any, i: number) => (
            <div
              key={i}
              className={`tab-pane fade ${tabValue === i ? "show active" : ""}`}
              id={`${item.Desc}-tab-pane`}
              role="tabpanel"
              aria-labelledby={`${item.Title}-tab`}
            >
              <div className="row mx-0">
                <div className="col-md-6">
                  <div className="d-flex justify-content-center flex-column h-100 tabMiddleContent">
                    <div className="learntabHead">
                      {parser(item?.Title )}
                    </div>
                    <div className="learntabContent">
                      {parser(item?.Desc)}
                    </div>
                    <div>
                      <button
                        className="LearnMorebtn px-4"
                        // onClick={() => goToLink("AdvanceAcademics")}
                      >
                        Learn More
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="tabMiddleContent">
                    <img
                      src={item.Img}
                      alt="Academic"
                      className="w-100 rounded"
                    />
                    <div className="row align-items-baseline bottomStripleft">
                      <div className="col-8 orangeStrip rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CourseType;
