
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../Assets/Css/pageNotFound.css';

function PageNotFound() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const gotoHome=()=>{
    navigate('/')
  }
  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div className="col-sm-10 col-sm-offset-1 text-center mx-auto">
            <h1 className="text-center text404 ">404</h1>
              <div className="four_zero_four_bg">
               
              </div>
              <div className="contant_box_404">
                <h3 className="h2">
                  Look like you're lost
                </h3>
                <p>the page you are looking for not avaible!</p>

                <div className="link_404 cursor" onClick={gotoHome}>Go to Home</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageNotFound;
