import React from 'react'
import "../../Assets/Css/SEOTemplate/LurnigoProcess.css"
import parser from "html-react-parser";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function LurnigoProcess({ Item }) {
 
  const LurnigoProcess = JSON.parse(Item.content) 
  const datassss = LurnigoProcess.courseArr 
  return (
    <div>
      <div className=" commonPad py-4 ">\
      <div className="width800 mx-auto mb-5">
      <h1 className="headingFont text-center">{LurnigoProcess.Heading}</h1></div>
      <div className="bannersubtext mb-3 text-center">{LurnigoProcess.SubHeading}</div>
        <div className=" d-flex justify-content-center align-items-center my-5 gap-2 m-auto flex-wrap ">
          {LurnigoProcess.courseArr.map((item: any, i: number) => (

            <div className=' main-card-sec position-relative'>
              <div className='arrowposition'></div>
              <div className="outer-border-sec " key={i}>
                <div ><img src={item.Img} alt="img" height={50} width={50} /></div>
                <div className="heading-cont">{parser(item.Title)}</div>
                <div className="sub-headingss px-4">{parser(item.Desc)}</div>
              </div>
            </div>

          ))}
        </div>
      </div>
    </div>
  )
}

export default LurnigoProcess
