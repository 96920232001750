import React from "react";
import parser from "html-react-parser";
 

function CourseFeatures({ Item }) {
    console.log("CourseFeatures",Item);
    const CourseFeatures=JSON.parse(Item.content)
    console.log("CourseFeatures",CourseFeatures);
    // console.log();
    
  return (
    <div>
      <div className="commonPad personalProgram">
        <div className="width800 mx-auto my-5">
          <h1 className="headingFont text-center">
            {CourseFeatures.Heading}
          </h1>
        </div>
        <div className="row ">
          {CourseFeatures.courseArr?.map((item:any, index:number) => (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
              <div className="border rounded p-3 h-100">
                <img
                  src={item?.Img}
                  alt={item?.AltText}
                  className="w-100"
                />
                <div className="programHeading fw-bold mt-3 mb-2 lh-sm">
                {parser(item?.Title)}
                </div>
                <div className="font16">{parser(item?.Desc)}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CourseFeatures;
